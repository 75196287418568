import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Pagination } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Layout from "components/layout/Layout";
import { Link } from "components/router";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import usePermissions from "shared/hooks/usePermissions";
import { listVehicles } from "shared/network/vehicle.api";
import { Vehicle } from "shared/types";
import MultipleTourDetailsContent from "views/tour/components/MultipleTourDetailsContent";
import TourDetailsContent from "views/tour/components/TourDetailsContent";
import VehicleListItem from "./components/VehicleListItem";

const useStyles = makeStyles(
  () => ({
    title: {
      fontWeight: "bold",
    },
  }),
  {
    name: "VehicleList",
  },
);

const VehicleList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(
    parseInt(window.sessionStorage.getItem("VehiclePageNumber") || JSON.stringify(0)),
  );
  const [date, setDate] = useState(new Date());
  const [selected, setSelected] = useState<Vehicle[]>([]);

  const currentCompany = useSelector((state: RootState) => state.application.currentCompany);

  const vehiclesQuery = useQuery(["vehicles", page, currentCompany?.id], async () => {
    const { data } = await listVehicles(page, 10, currentCompany?.id);
    return data;
  });

  const { checkPermissions, isAdmin, isCustomerService } = usePermissions();

  useEffect(() => {
    window.sessionStorage.setItem("VehiclePageNumber", JSON.stringify(page));
  }, [page]);

  return (
    <Layout
      title={t("vehicle.list.title")}
      actionButton={
        <Box display="flex" flexWrap="wrap">
          {vehiclesQuery.data && (
            <Button
              onClick={() =>
                selected.length < vehiclesQuery.data?.page.content.length
                  ? setSelected(vehiclesQuery.data?.page.content)
                  : setSelected([])
              }
              variant={
                selected.length < vehiclesQuery.data?.page.content.length ? "outlined" : "contained"
              }
              color="primary"
              style={{ margin: 8 }}
            >
              {t("common:button.selectAll")}
            </Button>
          )}
          {selected?.length === 1 && (
            <div style={{ maxWidth: 200, margin: 8 }}>
              <KeyboardDatePicker
                format="yyyy. MM. dd."
                label={t("tour.date")}
                value={date ?? new Date()}
                onChange={date => setDate(date ?? new Date())}
                autoOk
                size="small"
                fullWidth
                margin="none"
                cancelLabel={t("common:button.cancel")}
              />
            </div>
          )}
          {(isAdmin ||
            isCustomerService ||
            checkPermissions({
              requestedPermissions: ["SHIPORGANIZER"],
            })) && (
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              component={Link}
              to="/vehicle/create"
              startIcon={<AddIcon />}
              style={{ margin: 8 }}
            >
              {t("vehicle.create.title")}
            </Button>
          )}
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={selected?.length ? 4 : 12}>
          {vehiclesQuery.isFetching ? (
            <Box display="flex" alignItems="center" justifyContent="center" height="300px">
              <CircularProgress />
            </Box>
          ) : (
            <Box style={{ overflow: "auto hidden" }}>
              <Box style={{ minWidth: !selected?.length ? 1200 : undefined }}>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    padding: "12px 8px",
                  }}
                >
                  <Grid item xs={!selected?.length ? 1 : 6}>
                    <Typography className={classes.title}>
                      {t("vehicle.properties.licensePlateNumber")}
                    </Typography>
                  </Grid>
                  <Grid item xs={!selected?.length ? 1 : 6}>
                    <Typography className={classes.title}>
                      {t("vehicle.properties.brand")}
                    </Typography>
                  </Grid>
                  {!selected?.length && (
                    <>
                      <Grid item xs={1}>
                        <Typography className={classes.title}>
                          {t("vehicle.properties.type")}
                        </Typography>
                      </Grid>
                      <Grid item xs={!selected?.length ? 2 : 6}>
                        <Typography className={classes.title}>
                          {t("vehicle.properties.user")}
                        </Typography>
                      </Grid>
                      <Grid item xs={!selected?.length ? 2 : 6}>
                        <Typography className={classes.title}>
                          {t("vehicle.properties.companyName")}
                        </Typography>
                      </Grid>
                      <Grid item xs={!selected?.length ? 1 : 3}>
                        <Typography className={classes.title}>
                          {t("vehicle.properties.volume")}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.title}>
                          {t("vehicle.properties.weightCapacity")}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography className={classes.title}>
                          {t("vehicle.properties.prop")}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Divider />
                {vehiclesQuery.data?.page.content.length ? (
                  vehiclesQuery.data?.page.content?.map((vehicle, index, array) => (
                    <VehicleListItem
                      key={vehicle.id}
                      vehicle={vehicle}
                      index={index}
                      array={array}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ))
                ) : (
                  <Typography variant="h5" style={{ textAlign: "center", color: "gray" }}>
                    {t("common:noData")}
                  </Typography>
                )}
                {vehiclesQuery.data && vehiclesQuery?.data?.page.totalPages > 1 && (
                  <Pagination
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "24px 0",
                    }}
                    count={vehiclesQuery?.data?.page.totalPages}
                    color="primary"
                    page={vehiclesQuery.data.page.number + 1}
                    onChange={(e, page) => {
                      sessionStorage.setItem("VehiclePageNumber", JSON.stringify(page - 1));
                      setPage(page - 1);
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Grid>
        {selected?.length === 1 && (
          <Grid item xs={12} sm={12} md={8}>
            <TourDetailsContent id={selected?.[0]?.id?.toString() || ""} date={date} />
          </Grid>
        )}
        {selected?.length > 1 && (
          <Grid item xs={12} sm={12} md={8}>
            <MultipleTourDetailsContent selected={selected} />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

export default VehicleList;
