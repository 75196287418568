import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { Alert } from "@material-ui/lab";
import { createSelector } from "@reduxjs/toolkit";
import Button from "components/form/Button";
import PasswordTextField from "components/form/PasswordTextField";
import UnauthorizedLayout from "components/layout/UnauthorizedLayout";
import { Link as RouterLink } from "components/router";
import { RootState } from "config/store";
import { palette } from "config/theme";
import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { login } from "shared/reducers/authentication";
import { SliceStatus } from "shared/types";
import { EMAIL_REGEX } from "shared/util/validation";

export type LoginFormFields = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type LocationState = {
  from: {
    pathname: string;
    search: any;
  };
};

const useStyles = makeStyles(
  () => ({
    rememberMe: {
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: palette.lightMain,
      },
    },
  }),
  { name: "Login" },
);

const selector = createSelector(
  (state: RootState) => state.authentication,
  authentication => authentication,
);

const Login = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const { from } = location.state ?? { from: { pathname: "/dashboard", search: location.search } };
  const { status, isAuthenticated, error } = useSelector(selector);
  const { register, errors, handleSubmit } = useForm<LoginFormFields>();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("submit");

    return token;
  }, [executeRecaptcha]);

  function onSubmit(values: LoginFormFields) {
    handleReCaptchaVerify().then(async value => {
      if (value) {
        if (values.rememberMe) {
          document.cookie = "rememberMe=true";
        }
        dispatch(login(values.email, values.password, values.rememberMe));
      }
    });
  }

  if (status === "success" && isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <UnauthorizedLayout title={t("login.title")}>
      <Box display="flex" flexDirection="column" width="50%" margin="auto" minWidth="250px">
        {error && (
          <Alert severity="error">{t([`login.error.${error}`, "login.error.failure"])}</Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
          <TextField
            id="email"
            name="email"
            type="email"
            label={t("common:field.email")}
            inputRef={register({
              required: { value: true, message: t("validation:required") },
              pattern: {
                value: EMAIL_REGEX,
                message: t("validation:invalidFormat", {
                  subject: t("validation:caption.subject.email"),
                }),
              },
            })}
            error={errors.email && true}
            helperText={errors.email?.message}
          />
          <PasswordTextField
            id="password"
            name="password"
            type="password"
            label={t("common:field.password")}
            inputRef={register({
              required: { value: true, message: t("validation:required") },
            })}
            error={errors.password && true}
            helperText={errors.password?.message}
          />
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
            <FormControlLabel
              control={<Checkbox defaultChecked={false} name="rememberMe" />}
              label={t("login.rememberMe")}
              inputRef={register}
              className={classes.rememberMe}
            />
            <Link component={RouterLink} to="/forgotten-password">
              <Typography>{t("forgottenPassword.title")}</Typography>
            </Link>
          </Box>
          <Button loading={status === SliceStatus.Loading} color="primary" type="submit">
            {t("common:button.login")}
          </Button>
        </form>
      </Box>
    </UnauthorizedLayout>
  );
};

export default Login;
