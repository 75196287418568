import Box from "@material-ui/core/Box";
import Layout from "components/layout/Layout";
import Pagination from "@material-ui/lab/Pagination";
import Typography from "@material-ui/core/Typography";
import displayAddress from "shared/util/displayAddress";
import PackageList, { ColumnType } from "components/package/PackageList";
import PackageFilter from "components/package/PackageFilter";
import StorageItemList from "components/storage/StorageItemList";
import Loading from "components/Loading";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { hu } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { format } from "date-fns";
import { exportWarehousePackages, searchPackages } from "shared/network/package.api";
import { FilterValues } from "views/package/PackageListView";
import WarehouseUserModal from "views/warehouse/components/WarehouseUserModal";
import { getWarehouseById } from "shared/network/warehouse.api";
import { getStoragePagesByWarehouseId } from "shared/network/storage.api";
import { Button, CircularProgress, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Edit, GetApp } from "@material-ui/icons";
import PackageModal from "views/tour/components/PackageModal";
import PackageStatusModal from "components/package/PackageStatusModal";
import { Package } from "shared/types";
import ColumnSelector from "components/package/ColumnSelector";
import usePermissions from "shared/hooks/usePermissions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import fileDownload from "shared/util/fileDownload";
import { useSnackbar } from "notistack";

type Params = {
  id: string;
};

type Props = RouteComponentProps<Params>;

const WarehouseDetails = ({ match }: Props) => {
  const { t } = useTranslation();
  const id = match.params.id;
  const [page, setPage] = useState(0);
  const [storagePage, setStoragePage] = useState(0);
  const [filters, setFilters] = useState<FilterValues>({
    sort: "CREATED_ON",
    isDesc: "ASC",
  });
  const [filterOpen, setFilterOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);

  const warehouseQuery = useQuery(["warehouseQueryDetails", id], async () => {
    const { data } = await getWarehouseById(Number.parseInt(id));
    return data.item;
  });

  const warehouse = warehouseQuery.data;

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const { checkPermissions } = usePermissions();

  const storageQuery = useQuery(["storageQuery", storagePage, warehouse, id], async () => {
    const { data } = await getStoragePagesByWarehouseId({
      pageNumber: storagePage,
      pageSize: 10,
      warehouseId: id,
    });
    return data;
  });

  const [date, setDate] = useState(new Date());

  const downloadMutation = useMutation(
    async () => {
      const { data } = await exportWarehousePackages({
        ...filters,
        sort: filters.sort,
        date: format(date, "yyyy-MM-dd"),
        isDesc: filters.isDesc === "DESC",
        ignoreStatuses: false,
        warehouseId: Number.parseInt(id),
        targetDateNull:
          filters.targetDateNull === "YES"
            ? true
            : filters.targetDateNull === "NO"
            ? false
            : undefined,
        isAskLoader:
          filters.isAskLoader === "YES" ? true : filters.isAskLoader === "NO" ? false : undefined,
        isHazardous:
          filters.isHazardous === "YES" ? true : filters.isHazardous === "NO" ? false : undefined,
        isFragile:
          filters.isFragile === "YES" ? true : filters.isFragile === "NO" ? false : undefined,
        isStackable:
          filters.isStackable === "YES" ? true : filters.isStackable === "NO" ? false : undefined,
        isCashOnDelivery:
          filters.isCashOnDelivery === "YES"
            ? true
            : filters.isCashOnDelivery === "NO"
            ? false
            : undefined,
        isExpressDelivery:
          filters.isExpressDelivery === "YES"
            ? true
            : filters.isExpressDelivery === "NO"
            ? false
            : undefined,
        plannedToTour:
          filters.plannedToTour === "YES"
            ? true
            : filters.plannedToTour === "NO"
            ? false
            : undefined,
        markedToReturn:
          filters.markedToReturn === "YES"
            ? true
            : filters.markedToReturn === "NO"
            ? false
            : undefined,
        isDeliveryToWarehouse:
          filters.isDeliveryToWarehouse === "YES"
            ? true
            : filters.isDeliveryToWarehouse === "NO"
            ? false
            : undefined,
        failedDelivery:
          filters.isDeliveryToWarehouse === "YES"
            ? true
            : filters.isDeliveryToWarehouse === "NO"
            ? false
            : undefined,
      });
      return data;
    },
    {
      onSuccess: data => {
        fileDownload(
          data,
          `${warehouse?.name}_tartalma_(${format(date, "P", {
            locale: hu,
          })}).pdf`,
        );
      },
      onError: () => {
        enqueueSnackbar(
          t("common:notification.download.failure", {
            subject: t("contact.subject"),
          }),
          { variant: "error" },
        );
      },
    },
  );

  const packageQuery = useQuery(["packageQuery", page, filters, warehouse, id], async () => {
    const { data } = await searchPackages({
      pageNumber: page,
      pageSize: 10,
      data: {
        ...filters,
        sort: filters.sort,
        isDesc: filters.isDesc === "DESC",
        //@ts-ignore
        ownWarehouseId: Number.parseInt(id),
        isAskLoader:
          filters.isAskLoader === "YES" ? true : filters.isAskLoader === "NO" ? false : undefined,
        isHazardous:
          filters.isHazardous === "YES" ? true : filters.isHazardous === "NO" ? false : undefined,
        isFragile:
          filters.isFragile === "YES" ? true : filters.isFragile === "NO" ? false : undefined,
        isStackable:
          filters.isStackable === "YES" ? true : filters.isStackable === "NO" ? false : undefined,
        isCashOnDelivery:
          filters.isCashOnDelivery === "YES"
            ? true
            : filters.isCashOnDelivery === "NO"
            ? false
            : undefined,
        isExpressDelivery:
          filters.isExpressDelivery === "YES"
            ? true
            : filters.isExpressDelivery === "NO"
            ? false
            : undefined,
      },
    });
    return data;
  });

  const [columnOpen, setColumnOpen] = useState(false);
  const [columns, setColumns] = useState<ColumnType>({
    status: true,
    packageName: false,
    trackingNumber: true,
    volume: false,
    storageCode: false,
    weight: false,
    icons: true,
    companyName: false,
    shopPackageNumber: false,
    customerComment: false,
    customerEmail: false,
    customerName: false,
    customerPhone: false,
    sourceAddress: false,
    currentAddress: false,
    targetAddress: true,
    targetFromDate: false,
    targetToDate: false,
    sourceFromDate: false,
    sourceToDate: false,
    returnGoods: false,
  });

  useEffect(() => {
    const temp = localStorage.getItem("warehouse-package-columns");
    if (temp) {
      setColumns(JSON.parse(temp));
    }
  }, []);

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("warehouse-package-filter");
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    }
  }, []);

  return (
    <Layout
      title={t("warehouse.details.title")}
      actionButton={
        <Box display="flex" flexWrap="wrap" gridGap={8}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setStatusOpen(true)}
            startIcon={<Edit />}
          >
            {t("common:button.packageStatus")}
          </Button>
          <PackageModal
            open={statusOpen}
            setOpen={setStatusOpen}
            setSelectedPackage={setSelectedPackage}
          />
          {selectedPackage && (
            <PackageStatusModal
              pack={selectedPackage}
              setSelected={setSelectedPackage}
              refetch={packageQuery.refetch}
              setPackageModalOpen={() => setStatusOpen(true)}
            />
          )}
          {checkPermissions({
            requestedPermissions: ["CUSTOMER_SERVICE", "ADMIN"],
          }) &&
            warehouse && <WarehouseUserModal warehouse={warehouse} />}
          {checkPermissions({ requestedPermissions: ["WAREHOUSEKEEPER"] }) && (
            <>
              <Button
                onClick={() => downloadMutation.mutate()}
                variant="outlined"
                color="primary"
                style={{ height: 40, marginRight: 8 }}
                startIcon={
                  downloadMutation.isLoading ? (
                    <CircularProgress style={{ height: 20, width: 20 }} />
                  ) : (
                    <GetApp />
                  )
                }
                disabled={downloadMutation.isLoading}
              >
                {t("common:button.export")}
              </Button>
              <KeyboardDatePicker
                format="yyyy. MM. dd."
                label={t("tour.date")}
                value={date.setDate(date.getDate())}
                onChange={e => {
                  setDate(e ?? new Date());
                }}
                autoOk
                size="small"
                fullWidth={false}
                margin="none"
                cancelLabel={t("common:button.cancel")}
              />
            </>
          )}
        </Box>
      }
    >
      {warehouseQuery.isFetching ? (
        <Loading />
      ) : (
        <>
          <Grid container spacing={2} justify={"space-around"} style={{ padding: "12px 0" }}>
            <Grid item xs={12} sm={3}>
              <Typography style={{ fontSize: 20 }}>{warehouse?.name}</Typography>
              <Typography style={{ fontSize: 14, color: "darkgrey" }}>
                {t("warehouse.name")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography style={{ fontSize: 20 }}>
                {t("common:number", { num: warehouse?.height }) + " m"}
              </Typography>
              <Typography style={{ fontSize: 14, color: "darkgrey" }}>
                {t("warehouse.height")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography style={{ fontSize: 20 }}>
                {t("common:number", { num: warehouse?.space }) + " m²"}
              </Typography>
              <Typography style={{ fontSize: 14, color: "darkgrey" }}>
                {t("warehouse.space")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography style={{ fontSize: 20 }}>{displayAddress(warehouse?.address)}</Typography>
              <Typography style={{ fontSize: 14, color: "darkgrey" }}>
                {t("warehouse.address")}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            style={{
              fontSize: 12,
              background: "#fafafa",
              zIndex: 1,
              borderTop: "1px solid #ddd",
              padding: "12px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              style={{ borderRight: md ? "1px solid #ddd" : undefined }}
            >
              {warehouse && (
                <>
                  <StorageItemList
                    warehouse={warehouse}
                    storages={storageQuery.data?.page.content}
                    onSubmitSuccess={storageQuery.refetch}
                  />
                  <Box display="flex" width="100%" minWidth="250px" gridGap={12}>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        fontSize: 12,
                        position: "sticky",
                        top: 0,
                        background: "#fafafa",
                        zIndex: 1,
                        padding: "12px",
                      }}
                    >
                      <Grid item xs={12}>
                        {storageQuery.data && storageQuery?.data?.page.totalPages > 1 && (
                          <Pagination
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "24px 0",
                            }}
                            count={storageQuery?.data?.page.totalPages}
                            color="primary"
                            page={storageQuery.data.page.number + 1}
                            onChange={(e, page) => {
                              window.scrollTo(0, 0);
                              setStoragePage(page - 1);
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={7}>
              <Typography variant="h6" style={{ margin: 8 }}>
                {t("package.list")}
              </Typography>
              <ColumnSelector
                type="warehouse"
                open={columnOpen}
                columns={columns}
                setColumns={setColumns}
                setColumnOpen={setColumnOpen}
              />
              <PackageFilter
                disableWarehouseFilter
                open={filterOpen}
                onClose={() => setFilterOpen(false)}
                filters={filters}
                setFilters={setFilters}
                setPage={setPage}
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
                columnOpen={columnOpen}
                setColumnOpen={setColumnOpen}
                storageKey="warehouse-package-filter"
              />
              <PackageList
                columns={columns}
                packages={packageQuery.data?.page.content}
                refetch={packageQuery.refetch}
                loading={packageQuery.isFetching}
              />
              {packageQuery.data && packageQuery?.data?.page.totalPages > 1 && (
                <Pagination
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "24px 0",
                  }}
                  count={packageQuery?.data?.page.totalPages}
                  color="primary"
                  page={packageQuery.data.page.number + 1}
                  onChange={(e, page) => {
                    window.scrollTo(0, 0);
                    setPage(page - 1);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default WarehouseDetails;
