import axios from "config/axios";
import {
  GenericListResponse,
  GenericResponse,
  Vehicle,
  GenericPageResponse,
  GpsCoordinate,
} from "shared/types";
import { TourPlan } from "views/tour/Tour";

const ENDPOINT = "/vehicle";

export const createVehicle = (data: Partial<Vehicle> & { userId?: number; companyId: number }) =>
  axios.post<GenericResponse<Vehicle>>(ENDPOINT, { param: data });

export const modifyVehicle = (data: Partial<Vehicle> & { userId?: number; companyId: number }) =>
  axios.put<GenericResponse<Vehicle>>(ENDPOINT, { param: data });

export const setUserForVehicle = (data: { id: number; userId: number }) =>
  axios.put<GenericResponse<Vehicle>>(`${ENDPOINT}/set-user`, data);

export const getVehicleById = (vehicle: number) =>
  axios.get<GenericResponse<Vehicle>>(`${ENDPOINT}?id=${vehicle}`);

export const listVehicles = (page = 0, size = 10, companyId?: number, predicates: string = "") =>
  axios.get<GenericPageResponse<Vehicle>>(
    `${ENDPOINT}/list-pageable?page=${page}&size=${size}&search=${predicates}&companyId=${companyId}`,
  );

export const getAllVehicles = () => axios.get<GenericListResponse<Vehicle>>(`${ENDPOINT}/get-all`);

export const getVechiclesNotOnTour = (date: string) =>
  axios.get<GenericListResponse<TourPlan>>(`${ENDPOINT}/with-tour?date=${date}`);

export const getVechiclesNotOnTourLight = (date: string) =>
  axios.get<GenericListResponse<TourPlan>>(`${ENDPOINT}/with-tour-light?date=${date}`);

export const getVechiclesOnTour = (date: string) =>
  axios.get<GenericListResponse<Vehicle>>(`${ENDPOINT}/list-on-tour?date=${date}`);

export const getVehicleCoordinates = (data: any) => axios.post(`${ENDPOINT}/coordinates`, data);

export const getVehicleGpsByDay = (
  vehicleId: number | undefined,
  localDate: Date | string | undefined,
) =>
  axios.put<GenericListResponse<GpsCoordinate>>(`gps/list-for-vehicle-by-day`, {
    vehicleId,
    localDate,
  });

export const getDailySummary = (date: string) =>
  axios.get<any>(`${ENDPOINT}/export-daily-summary?date=${date}`);
