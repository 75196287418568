import axios from "config/axios";

const ENDPOINT = "/circuit-api";

export const createPlanWithStops = (vehicleId: number, date: string) =>
  axios.post(`${ENDPOINT}/create-plan-with-stops`, { vehicleId, date });

export const deletePlan = (planId: string) => axios.delete(`${ENDPOINT}/plan?planId=${planId}`);

export const optimizePlan = (planId: string) =>
  axios.post(`${ENDPOINT}/plan-optimize?planId=${planId}`);
