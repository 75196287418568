import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Add, Camera, Edit } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Button from "components/form/Button";
import PackageNoteForm, { PackageNoteFormValues } from "components/package/PackageNoteForm";
import SignatureCanvas from "components/SignatureCanvas";
import {
  SHIPPER_GIVEN_STATUSES,
  SHOPKEEPER_GIVEN_STATUSES,
  statuses,
  WAREHOUSEKEEPER_GIVEN_STATUSES,
} from "config/constants";
import { RootState } from "config/store";
import { palette } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import usePermissions from "shared/hooks/usePermissions";
import { createNote } from "shared/network/notes.api";
import {
  createSignature,
  getShipmentPackages,
  modifyPackageStatus,
} from "shared/network/package.api";
import { Package } from "shared/types";
import { useDebouncedCallback } from "use-debounce/lib";
import { useImmer } from "use-immer";

type Props = {
  pack?: Package | null;
  setSelected: (selected: Package | null) => void;
  refetch?: () => void;
  setPackageModalOpen?: () => void;
};

const useStyles = makeStyles(() => ({
  header: {
    color: palette.main,
    fontSize: 16,
    marginTop: 15,
  },
  packHeader: {
    color: palette.main,
    fontSize: 16,
  },
}));

type StatusUpdate = {
  status: string;
  packageId: number;
  companyId: number;
  vehicleId: number;
  warehouseId: number;
  storageCode: string;
  paymentType: string;
} & PackageNoteFormValues;

const PackageStatusModal = ({ pack, setSelected, refetch, setPackageModalOpen }: Props) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [noteForm, setNoteform] = useState(false);
  const [response, setResponse] = useState<any>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedPackages, setSelectedPackages] = useImmer<Package[]>([]);
  const [useFullShipment, setUseFullShipment] = useState(false);

  const [offset, setOffset] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  const companyId = useSelector((state: RootState) => state.application.currentCompany?.id);
  const form = useForm<StatusUpdate>({
    shouldUnregister: false,
  });
  const { watch, setValue, reset, handleSubmit, errors, register } = form;

  const selectedStatus = watch("status");
  const { isAdmin, isCustomerService, checkPermissions, companyPermissions } = usePermissions();
  const isWarehouseKeeper =
    companyPermissions?.includes("WAREHOUSEKEEPER") ||
    companyPermissions?.includes("WAREHOUSEKEEPER_ADMIN");
  const isShipper =
    companyPermissions?.includes("SHIPPER") || companyPermissions?.includes("SHIPPERADMIN");

  useEffect(() => {
    if (isShipper && !isAdmin) {
      setQrOpen(false);
    } else {
      setQrOpen(false);
    }
  }, [isShipper, isAdmin]);

  const connectedPackagesQuery = useQuery(
    ["connectedPackagesForStatusUpdate", pack?.shipment?.id],
    async () => {
      const { data } = await getShipmentPackages(pack?.shipment?.id || 0);
      return data.items.filter(entry => entry.trackingNumber !== pack?.trackingNumber);
    },
    {
      enabled: !!pack?.shipment?.id,
      onSuccess: data => {
        setSelectedPackages([]);
        setSelectAll(false);
      },
    },
  );

  useEffect(() => {
    setSelectedPackages(selectedPackages => (selectAll ? connectedPackagesQuery.data : []));
  }, [selectAll, setSelectedPackages]); //eslint-disable-line

  useEffect(() => {
    setOffset({ top: useFullShipment ? 67 : 0, left: 0 });
  }, [useFullShipment, selectedStatus]);

  function onSelect(selected: boolean, entry: Package) {
    if (selected && !selectedPackages.includes(entry)) {
      setSelectedPackages(selectedPackages => [...selectedPackages, entry]);
    } else if (!selected && selectedPackages.includes(entry)) {
      setSelectedPackages(selectedPackages =>
        selectedPackages.filter(selected => selected.id !== entry.id),
      );
    }
  }

  useEffect(() => {
    if (selectedStatus === "TAKEN_INTO_STORAGE" || selectedStatus === "REMOVED_FROM_STORAGE") {
      document.getElementById("storageCode")?.focus();
    }
  }, [selectedStatus, pack]);

  const onClose = () => {
    setSelected(null);
    if (isShipper && !isAdmin) {
      setQrOpen(false);
    } else {
      setQrOpen(false);
    }
    refetch?.();
    reset();
    setValue("storageCode", "");
    setValue("status", isWarehouseKeeper ? "TAKEN_INTO_STORAGE" : "");
    setNoteform(false);
  };

  const handleScan = (data: string | null) => {
    if (data) {
      setValue("storageCode", data);
      setQrOpen(false);
      // onSubmit(watch());
    }
  };

  const handleError = () => {
    enqueueSnackbar(t("package.qrError"), {
      variant: "error",
    });
  };

  const onSubmit = async (values: StatusUpdate) => {
    setLoading(true);
    if (pack?.id && companyId) {
      try {
        const { data } = await modifyPackageStatus({
          status: values.status,
          storageCode: values.storageCode?.trim(),
          type: useFullShipment ? "SHIPMENT" : "PACKAGE",
          shipmentId: useFullShipment ? pack.shipment.id : undefined,
          packageIds: useFullShipment
            ? undefined
            : [...selectedPackages.map(entry => entry.id), pack?.id],
          companyId: companyId,
          paymentType: values.paymentType,
          comment: values.note || null,
          refuseType: values.type || null,
        });
        if (values.note || values.file?.[0]) {
          await createNote({
            file: values.file[0],
            note: values.note,
            subjectId: pack.id,
            subjectType: "PACKAGES",
            type: values.type || "COMMENT",
          });
        }
        if (selectedStatus === "SUCCESSFUL_DELIVERY" || selectedStatus === "GIVEN_TO_BUYER") {
          const canvas = document.getElementById("signature") as HTMLCanvasElement;
          if (canvas) {
            canvas.toBlob(async blob => {
              if (blob) {
                await createSignature({
                  file: new File([blob], "signature.jpg", {
                    type: "image/jpg",
                  }),
                  packageId: pack.id,
                  companyId,
                });
              }
            }, "image/jpg");
          }
        }
        enqueueSnackbar(
          t("common:notification.update.success", {
            subject: t("package.status.subject"),
          }),
          { variant: "success" },
        );
        if (data.packageSize) {
          setResponse(data);
          setValue("storageCode", "");
        } else {
          onClose();
        }
        onClose();
        setPackageModalOpen?.();
      } catch (error) {
        if (isShipper && !isAdmin) {
          setQrOpen(false);
        }
        if ((error as any)?.data?.status === "PAYMENT_TYPE_REQUIRED") {
          enqueueSnackbar(t("package.PAYMENT_TYPE_REQUIRED_ERROR"), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            t("common:notification.update.failure", {
              subject: t("package.subject"),
            }),
            { variant: "error" },
          );
        }
      }
    }
    setLoading(false);
  };

  function getStatusOptions() {
    let statusOptions: string[];
    if (isCustomerService || checkPermissions({ requestedPermissions: ["SHIPORGANIZER"] })) {
      statusOptions = statuses;
    } else {
      statusOptions = [];
      if (checkPermissions({ requestedPermissions: ["SHIPPER"] })) {
        statusOptions = statusOptions.concat(SHIPPER_GIVEN_STATUSES);
      }
      if (checkPermissions({ requestedPermissions: ["SHOPKEEPER"] })) {
        statusOptions = statusOptions.concat(SHOPKEEPER_GIVEN_STATUSES);
      }
      if (
        checkPermissions({
          requestedPermissions: ["WAREHOUSEKEEPER", "WAREHOUSEKEEPER_ADMIN"],
        })
      ) {
        statusOptions = statusOptions.concat(WAREHOUSEKEEPER_GIVEN_STATUSES);
      }
    }
    return statusOptions;
  }

  const handleChange = useDebouncedCallback(event => {
    if (event.target.value) {
      event.stopPropagation();
      handleSubmit(onSubmit)(event);
    }
  }, 300);

  return (
    <FormProvider {...form}>
      <Dialog
        fullScreen={!matchesSm}
        open={!!pack}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          component: "form",
          onSubmit: e => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          },
          style: { margin: 8 },
        }}
      >
        <DialogTitle style={{ paddingBottom: 8 }}>
          <Box display="flex" justifyContent="space-between" flexDirection="column">
            {t("package.status.modify")}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gridGap={8}
              paddingRight={2}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={useFullShipment}
                    onChange={(event, checked) => setUseFullShipment(checked)}
                  />
                }
                label={t("package.usePackages")}
                labelPlacement="start"
              />
              <Typography>{t("package.useFullShipment")}</Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <>
            {response?.packageSize && (
              <Alert severity="warning">
                {t("package.status.alert", {
                  size: response?.packageSize,
                })}
              </Alert>
            )}
            {!useFullShipment && (
              <>
                <Grid container spacing={1} style={{ paddingBottom: 8 }}>
                  <Grid item xs={12} container style={{ borderBottom: "1px solid grey" }}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                      <Typography style={{ fontWeight: "bold" }}>Választott csomag</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container spacing={1}>
                    <Grid item xs={1} />
                    <Grid item xs={6}>
                      <Typography>{pack?.trackingNumber}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>{pack?.name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    container
                    style={{ borderBottom: "1px solid grey" }}
                    spacing={1}
                  >
                    <Grid item xs={1} container justify="center">
                      <Checkbox
                        style={{ height: 30, width: 30 }}
                        size="small"
                        checked={selectAll}
                        onChange={event => setSelectAll(event.target.checked)}
                        color="primary"
                      />
                    </Grid>
                    <Grid item xs={11} container alignContent="center">
                      <Typography className={classes.packHeader} style={{ fontWeight: "bold" }}>
                        Kapcsolódó csomagok
                      </Typography>
                    </Grid>
                  </Grid>
                  {connectedPackagesQuery.data?.map((entry, index) => {
                    return (
                      <Grid item xs={12} container key={index} spacing={1}>
                        <Grid item xs={1} container justify="center">
                          <Checkbox
                            style={{ height: 30, width: 30 }}
                            size="small"
                            checked={selectedPackages.includes(entry)}
                            onChange={event => onSelect(event.target.checked, entry)}
                            color="primary"
                          />
                        </Grid>
                        <Grid item xs={6} container alignContent="center">
                          <Typography>{entry.trackingNumber}</Typography>
                        </Grid>
                        <Grid item xs={5} container alignContent="center">
                          <Typography>{entry.name}</Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )}
            <Controller
              name="status"
              defaultValue={
                checkPermissions({
                  requestedPermissions: ["WAREHOUSEKEEPER", "WAREHOUSEKEEPER_ADMIN"],
                })
                  ? "TAKEN_INTO_STORAGE"
                  : ""
              }
              rules={{
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              }}
              render={props => (
                <TextField
                  {...props}
                  onChange={e => {
                    props.onChange(e);
                    if (e.target.value === "FAILED_DELIVERY") {
                      setNoteform(true);
                    }
                  }}
                  label={t("package.status.newTitle")}
                  InputLabelProps={{ shrink: true, required: true }}
                  SelectProps={{ displayEmpty: true }}
                  error={errors?.status && true}
                  helperText={errors?.status?.message}
                  select
                >
                  <MenuItem value="" disabled>
                    <em>{t("common:choose")}</em>
                  </MenuItem>
                  {getStatusOptions()?.map(status => (
                    <MenuItem key={status} value={status}>
                      {t(`package.status.${status}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            {!pack?.isCashOnDelivery && selectedStatus === "SUCCESSFUL_DELIVERY" && (
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="PAYMENT_TYPE"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <Typography className={classes.header}>
                        {t("package.PAYMENT_MODE")}
                      </Typography>
                      <Typography
                        style={{
                          color: "#4f4f4e",
                          fontStyle: "italic",
                          marginBottom: 15,
                        }}
                      >
                        {t("package.paid")}
                      </Typography>
                      <Typography className={classes.header}>{t("package.SIGNATURE")}</Typography>
                    </FormControl>
                  )}
                />
              </Grid>
            )}
            {pack?.isCashOnDelivery && selectedStatus === "SUCCESSFUL_DELIVERY" && (
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  }}
                  name="paymentType"
                  defaultValue=""
                  render={props => (
                    <FormControl component="fieldset">
                      <Typography className={classes.header}>
                        {t("package.PAYMENT_MODE")}
                      </Typography>
                      <RadioGroup
                        {...props}
                        row
                        name="paymentType"
                        onChange={e => props.onChange(e.target.value)}
                      >
                        <FormControlLabel
                          value="CARD"
                          control={<Radio />}
                          label={t("package.CARD")}
                        />
                        <FormControlLabel
                          value="CASH"
                          control={<Radio />}
                          label={t("package.CASH")}
                        />
                      </RadioGroup>
                      {errors.paymentType && (
                        <FormHelperText style={{ color: "red" }}>
                          {errors.paymentType?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            )}
            {(selectedStatus === "TAKEN_INTO_STORAGE" ||
              selectedStatus === "REMOVED_FROM_STORAGE") && (
              <>
                {!qrOpen && (
                  <>
                    <TextField
                      id="storageCode"
                      name="storageCode"
                      label={t("package.storageCode")}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: t("validation:required"),
                        },
                      })}
                      error={errors?.storageCode && true}
                      helperText={errors?.storageCode?.message}
                      onChange={handleChange}
                    />
                    <Button
                      variant="outlined"
                      startIcon={<Camera />}
                      onClick={() => setQrOpen(true)}
                      style={{
                        marginTop: 12,
                        marginBottom: 8,
                        width: "100%",
                      }}
                    >
                      {t("package.qrCodeButton")}
                    </Button>
                  </>
                )}
                {qrOpen && (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<Edit />}
                      onClick={() => setQrOpen(false)}
                      style={{
                        marginBottom: 8,
                        width: "100%",
                      }}
                    >
                      {t("package.storageNumberButton")}
                    </Button>
                    <QrReader
                      delay={100}
                      resolution={500}
                      onError={handleError}
                      onScan={handleScan}
                    />
                  </>
                )}
              </>
            )}
          </>
          {(selectedStatus === "SUCCESSFUL_DELIVERY" || selectedStatus === "GIVEN_TO_BUYER") && (
            <SignatureCanvas offsetTop={offset.top} offsetLeft={offset.left} />
          )}
          {noteForm ? (
            <PackageNoteForm noteRequired={selectedStatus === "FAILED_DELIVERY"} />
          ) : (
            <Box display="flex" justifyContent="center" mt={2} mb={1}>
              <Button
                onClick={() => setNoteform(true)}
                variant="outlined"
                color="primary"
                startIcon={<Add />}
              >
                {t("package.properties.customerComment")}
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              onClose();
            }}
          >
            {t("common:button.cancel")}
          </Button>
          {pack && (
            <>
              {pack.actualStatus.status !== "NEW_PACKAGE" && !isAdmin ? (
                <>
                  {selectedStatus === "DELETED" ? (
                    <Button
                      loading={loading}
                      type="submit"
                      disabled
                      color="primary"
                      variant="contained"
                    >
                      {t("common:button.save")}
                    </Button>
                  ) : (
                    <Button loading={loading} type="submit" color="primary" variant="contained">
                      {t("common:button.save")}
                    </Button>
                  )}
                </>
              ) : (
                <Button loading={loading} type="submit" color="primary" variant="contained">
                  {t("common:button.save")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default PackageStatusModal;
