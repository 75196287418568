import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Share } from "@material-ui/icons";
import Button from "components/form/Button";
import JoditEditor from "jodit-react";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getContactsByShopId, sendEmail } from "shared/network/contact.api";
import { Note, Package } from "shared/types";
import { EMAIL_REGEX } from "shared/util/validation";
import { useImmer } from "use-immer";

type PackageNoteShareFormValues = {
  description: string;
  recipientHelper: boolean;
  loading: boolean;
};

type Props = {
  pack: Package;
  note: Note;
};

const PackageNoteShareModal = ({ note, pack }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<null | HTMLButtonElement>(null);
  const [recipients, setRecipients] = useImmer<{ email: string }[]>([]);

  const popoverForm = useForm();

  const form = useForm<PackageNoteShareFormValues>({
    defaultValues: {
      description: `Csomag követési száma: ${pack.trackingNumber}<br/>${note.note || ""}`,
    },
  });

  const contactByShopQuery = useQuery(["contactsByShopQuery", pack.shop.id], async () => {
    const { data } = await getContactsByShopId(pack.shop.id);
    return data.items;
  });

  const onSubmit = form.handleSubmit(async values => {
    setLoading(true);
    try {
      await sendEmail({
        contactIdList: [],
        emails: recipients.map(rec => rec.email),
        description: values.description,
      });
      setOpen(false);
      enqueueSnackbar(t("package.noteEmailSent"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("package.noteEmailError"), { variant: "error" });
    }
    setLoading(false);
  });

  useEffect(() => {
    form.register("recipientHelper", {
      validate: () => {
        if (!recipients.length) {
          return "Legalább egy címzett kell a megosztáshoz.";
        }
      },
    });
  }, [form, recipients.length]);

  const textAreaConfig = useMemo(() => {
    return {
      minHeight: 300,
      maxHeight: 400,
      showPlaceholder: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
    } as any;
  }, []);

  return (
    <>
      <Tooltip title={"Megosztás"}>
        <IconButton onClick={() => setOpen(true)} size="small">
          <Share />
        </IconButton>
      </Tooltip>
      <FormProvider {...form}>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            component: "form",
            onSubmit,
          }}
        >
          <DialogTitle>{t("package.noteSend")}</DialogTitle>
          <DialogContent>
            <Box display="flex" gridGap={8} flexWrap="wrap">
              {recipients.map((recipient, index) => (
                <>
                  <Chip
                    key={index}
                    variant="default"
                    label={
                      <Box display="flex">
                        <Typography style={{ fontSize: 12 }}>{recipient.email}</Typography>
                      </Box>
                    }
                    onDelete={() =>
                      setRecipients(state => {
                        state.splice(index, 1);
                      })
                    }
                    color="secondary"
                  />
                </>
              ))}
            </Box>
            <Button
              onClick={e => setPopoverAnchor(e.currentTarget)}
              variant="outlined"
              color="primary"
              startIcon={<Add />}
              style={{ margin: "8px 0" }}
            >
              {t("package.newAddress")}
            </Button>
            {form.errors.recipientHelper && (
              <FormHelperText error style={{ marginBottom: 16 }}>
                {form.errors.recipientHelper.message}
              </FormHelperText>
            )}
            <Popover
              open={!!popoverAnchor}
              anchorEl={popoverAnchor}
              onClose={() => setPopoverAnchor(null)}
              PaperProps={{ style: { padding: 16 } }}
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              transformOrigin={{ horizontal: "center", vertical: "top" }}
            >
              <form
                onSubmit={e => {
                  e.stopPropagation();
                  popoverForm.handleSubmit(values => {
                    if (values.contacts?.length) {
                      values.contacts?.forEach((contact: string) => {
                        setRecipients(state => {
                          state.push({ email: contact });
                        });
                      });
                    }

                    if (values.email) {
                      setRecipients(state => {
                        state.push({ email: values.email });
                      });
                    }

                    popoverForm.reset();
                    setPopoverAnchor(null);
                  })(e);
                }}
              >
                <Typography style={{ marginBottom: 16 }}>{t("package.newAddress")}</Typography>
                <Controller
                  control={popoverForm.control}
                  name="contacts"
                  defaultValue={[]}
                  render={props => (
                    <TextField
                      {...props}
                      margin="none"
                      label={t("package.properties.customerEmail")}
                      select
                      size="small"
                      SelectProps={{ multiple: true }}
                      style={{ textAlign: "center" }}
                      error={popoverForm.errors.contacts && true}
                      helperText={popoverForm.errors.contacts?.message}
                    >
                      <MenuItem value={[]} style={{ textAlign: "center" }}>
                        <em>{t("common:choose")}</em>
                      </MenuItem>
                      {contactByShopQuery.data
                        ?.filter(
                          value => !recipients.find(recipient => recipient.email === value.email),
                        )
                        .map(shop => (
                          <MenuItem key={shop.id} value={shop.email}>
                            {shop.email}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
                <TextField
                  name="email"
                  label={t("userList.email")}
                  inputRef={popoverForm.register({
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t("validation:invalidFormat", {
                        subject: t("validation:caption.subject.email"),
                      }),
                    },
                    validate: value => {
                      if (recipients.some(recipient => recipient.email === value)) {
                        return "Ez a címzett már fel van véve";
                      }
                    },
                  })}
                  error={popoverForm.errors.email && true}
                  helperText={popoverForm.errors.email?.message}
                />
                <Button type="submit" variant="contained" color="primary">
                  {t("common:button.record")}
                </Button>
                <Button onClick={() => setPopoverAnchor(null)}>{t("common:button.cancel")}</Button>
              </form>
            </Popover>
            <Controller
              name="description"
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              }}
              render={props => (
                <JoditEditor
                  config={textAreaConfig}
                  ref={props.ref}
                  value={props.value}
                  onChange={content => {
                    props.onChange(content);
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained" loading={loading}>
              {t("common:button.save")}
            </Button>
            <Button onClick={() => setOpen(false)}>{t("common:button.cancel")}</Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default PackageNoteShareModal;
