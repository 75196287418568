import { Button, Switch } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Layout from "components/layout/Layout";
import ColumnSelector from "components/package/ColumnSelector";
import PackageFilter from "components/package/PackageFilter";
import { ColumnType } from "components/package/PackageList";
import { palette } from "config/theme";
import { endOfDay, format, startOfDay } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { searchPackages } from "shared/network/package.api";
import {
  getDailySummary,
  getVechiclesNotOnTour,
  getVechiclesNotOnTourLight,
} from "shared/network/vehicle.api";
import { Address, Package, Vehicle } from "shared/types";
import { FilterValues } from "views/package/PackageListView";
import AddressModal from "./components/AddressModal";
import PackageListItem from "./components/PackageListItem";
import TourSortingModal, { Tour as TourType } from "./components/TourSortingModal";
import VehicleRow from "./components/VehicleRow";
import WarningModal from "./components/WarningModal";
import { useSnackbar } from "notistack";
import usePermissions from "shared/hooks/usePermissions";
import { GetApp } from "@material-ui/icons";

export type TourPlan = {
  hasTour?: boolean;
  vehicleEntry: Vehicle;
  tourEntryList: TourType[];
};

export type RelTourPackage = {
  vehicleEntry: Vehicle;
  tourEntryList: TourType;
  relTourPackagesEntryList: {
    createdBy: number;
    id: number;
    packages: Package;
    packagesType: "UP" | "DOWN";
    tour: TourType;
    updatedBy: number;
  };
};

export type WarningDataType = {
  param: {
    date: string;
    packages: Package;
    targetAddress: Address;
    tourOrder: number;
    vehiclle: Vehicle;
  };
} | null;

const Tour = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user, isAdmin, checkPermissions } = usePermissions();

  const [enableMultiSelect, setEnableMultiSelect] = useState(false);
  const [open, setOpen] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isntLight, setIsntLight] = useState(false);
  const [date, setDate] = useState(new Date());
  const [selected, setSelected] = useState<TourPlan>(null!);
  const [warningData, setWarningData] = useState<WarningDataType>(null);
  const [addressData, setAddressData] = useState<{ param: any } | null>(null);
  const [toWarehouseChecked, setToWarehouseChecked] = useState(false);
  const [page, setPage] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState<FilterValues>({
    sort: "CREATED_ON",
    isDesc: "ASC",
    sourceDateFrom: startOfDay(date).toISOString(),
    sourceDateTo: endOfDay(date).toISOString(),
    targetDateFrom: startOfDay(date).toISOString(),
    targetDateTo: endOfDay(date).toISOString(),
  });

  const [selectedPackages, setSelectedPackages] = useState<Package[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

  function startMultiPlanning() {
    setAddressData({
      param: {
        date: format(date, "yyyy-MM-dd"),
        packagesList: selectedPackages,
        vehicle: selectedVehicle,
        tourOrder: 1,
      },
    });
  }

  function resetMultiSelection() {
    setEnableMultiSelect(false);
    setSelectedPackages([]);
    setSelectedVehicle(null);
  }

  function addPackage(pack: Package) {
    setSelectedPackages(selectedPackages => [...selectedPackages, pack]);
  }

  function removePackage(pack: Package) {
    setSelectedPackages(selectedPackages.filter(entry => entry.id !== pack.id));
  }

  const vehiclesQuery = useQuery(["vehiclesQuery", date, isntLight], async () => {
    if (date) {
      if (isntLight) {
        const { data } = await getVechiclesNotOnTour(format(date, "yyyy-MM-dd"));
        return data.items;
      } else {
        const { data } = await getVechiclesNotOnTourLight(format(date, "yyyy-MM-dd"));
        return data.items;
      }
    }
    return Promise.reject();
  });

  const packagesQuery = useQuery(["packages", page, filters], async () => {
    const { data } = await searchPackages({
      pageNumber: page,
      pageSize: 15,
      data: {
        ...filters,
        sort: filters.sort,
        isDesc: filters.isDesc === "DESC",
        ignoreStatuses: true,
        targetDateNull:
          filters.targetDateNull === "YES"
            ? true
            : filters.targetDateNull === "NO"
            ? false
            : undefined,
        isAskLoader:
          filters.isAskLoader === "YES" ? true : filters.isAskLoader === "NO" ? false : undefined,
        isHazardous:
          filters.isHazardous === "YES" ? true : filters.isHazardous === "NO" ? false : undefined,
        isFragile:
          filters.isFragile === "YES" ? true : filters.isFragile === "NO" ? false : undefined,
        isStackable:
          filters.isStackable === "YES" ? true : filters.isStackable === "NO" ? false : undefined,
        isCashOnDelivery:
          filters.isCashOnDelivery === "YES"
            ? true
            : filters.isCashOnDelivery === "NO"
            ? false
            : undefined,
        isExpressDelivery:
          filters.isExpressDelivery === "YES"
            ? true
            : filters.isExpressDelivery === "NO"
            ? false
            : undefined,
        plannedToTour:
          filters.plannedToTour === "YES"
            ? true
            : filters.plannedToTour === "NO"
            ? false
            : undefined,
        markedToReturn:
          filters.markedToReturn === "YES"
            ? true
            : filters.markedToReturn === "NO"
            ? false
            : undefined,
        isDeliveryToWarehouse:
          filters.isDeliveryToWarehouse === "YES"
            ? true
            : filters.isDeliveryToWarehouse === "NO"
            ? false
            : undefined,
        failedDelivery:
          filters.isDeliveryToWarehouse === "YES"
            ? true
            : filters.isDeliveryToWarehouse === "NO"
            ? false
            : undefined,
      },
    });
    return data;
  });

  const [columnOpen, setColumnOpen] = useState(false);
  const [columns, setColumns] = useState<ColumnType>({
    status: false,
    packageName: false,
    trackingNumber: true,
    volume: true,
    storageCode: false,
    weight: true,
    icons: false,
    companyName: true,
    shopPackageNumber: false,
    customerComment: false,
    customerEmail: false,
    customerName: true,
    customerPhone: false,
    sourceAddress: true,
    currentAddress: true,
    targetAddress: true,
    targetFromDate: false,
    targetToDate: false,
    sourceFromDate: false,
    sourceToDate: false,
    returnGoods: false,
  });

  useEffect(() => {
    const temp = localStorage.getItem("tour-package-columns");
    if (temp) {
      setColumns(JSON.parse(temp));
    }
  }, []);

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("tour-package-filter");
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    }
  }, []);

  useEffect(() => {
    setToWarehouseChecked(addressData?.param?.packages?.isDeliveryToWarehouse);
  }, [addressData?.param?.packages?.isDeliveryToWarehouse]);

  let temp = Object.values(columns).filter((column: boolean) => column);

  return (
    <Layout
      title={t("tour.plans")}
      maxWidth="xl"
      actionButton={
        <Box display="flex" alignItems="center" flexWrap="wrap" gridGap={8}>
          {isAdmin && (
            <Box>
              <Button
                style={{ height: 40 }}
                color="primary"
                variant="outlined"
                startIcon={
                  downloadLoading ? (
                    <CircularProgress style={{ height: 20, width: 20 }} />
                  ) : (
                    <GetApp />
                  )
                }
                onClick={async () => {
                  setDownloadLoading(true);
                  try {
                    if (date) {
                      const { data } = await getDailySummary(format(date, "yyyy-MM-dd"));
                      const fileName = data?.item?.fileName || "";
                      let a = document.createElement("a");
                      a.setAttribute("download", fileName);
                      a.setAttribute(
                        "href",
                        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
                          data.item.content,
                      );
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                      enqueueSnackbar(t("tour.exportSuccess"), {
                        variant: "success",
                      });
                    }
                  } catch (error: any) {
                    enqueueSnackbar(t("tour.exportError"), {
                      variant: "error",
                    });
                  }
                  setDownloadLoading(false);
                }}
              >
                {t("tour.export")}
              </Button>
            </Box>
          )}
          <KeyboardDatePicker
            format="yyyy. MM. dd."
            label={t("tour.date")}
            value={date ?? new Date()}
            style={{ width: 200 }}
            onChange={date => {
              setDate(date ?? new Date());
              setFilters(prevState => {
                if (date) {
                  return {
                    ...prevState,
                    sourceDateFrom: startOfDay(date).toISOString(),
                    sourceDateTo: endOfDay(date).toISOString(),
                    targetDateFrom: startOfDay(date).toISOString(),
                    targetDateTo: endOfDay(date).toISOString(),
                  };
                } else {
                  return prevState;
                }
              });
            }}
            autoOk
            size="small"
            fullWidth
            margin="none"
            cancelLabel={t("common:button.cancel")}
          />
        </Box>
      }
    >
      <Box overflow="auto hidden">
        <Box display="flex" minWidth="1250px">
          <Box width="77%">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" style={{ fontWeight: "bold", paddingBottom: 12 }}>
                {t("tour.packages")}
              </Typography>
            </Box>
            <ColumnSelector
              type="tour"
              open={columnOpen}
              columns={columns}
              setColumns={setColumns}
              setColumnOpen={setColumnOpen}
              columnNumber={temp.length}
            />
            <PackageFilter
              open={filterOpen}
              onClose={() => setFilterOpen(false)}
              filters={filters}
              setFilters={setFilters}
              setPage={setPage}
              filterOpen={filterOpen}
              setFilterOpen={setFilterOpen}
              columnOpen={columnOpen}
              setColumnOpen={setColumnOpen}
              enableMultiSelect={enableMultiSelect}
              setEnableMultiSelect={setEnableMultiSelect}
              startMultiPlanning={startMultiPlanning}
              packagesSelected={!isEmpty(selectedPackages)}
              vehicleSelected={!!selectedVehicle}
              resetMultiSelection={resetMultiSelection}
              storageKey="tour-package-filter"
            />
            {packagesQuery.isFetching ? (
              <Box display="flex" alignItems="center" justifyContent="center" height="300px">
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <Box width="100%">
                  {!!packagesQuery.data?.page.content.length && (
                    <>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        minHeight={50}
                        borderBottom="1px solid rgba(224, 224, 224, 1)"
                      >
                        <div
                          style={{
                            marginRight: 36,
                          }}
                        />
                        {columns.packageName && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.properties.name")}
                            </Typography>
                          </Box>
                        )}
                        {columns.trackingNumber && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.properties.trackingNumber")}
                            </Typography>
                          </Box>
                        )}
                        {columns.volume && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("tour.volume")}
                            </Typography>
                          </Box>
                        )}
                        {columns.weight && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("tour.weight")}
                            </Typography>
                          </Box>
                        )}
                        {!!columns.status && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.status.title")}
                            </Typography>
                          </Box>
                        )}
                        {!!columns.storageCode && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.storageCode")}
                            </Typography>
                          </Box>
                        )}
                        {columns.companyName && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("tour.companyName")}
                            </Typography>
                          </Box>
                        )}
                        {columns.customerName && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.customer")}
                            </Typography>
                          </Box>
                        )}
                        {!!columns.customerEmail && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.properties.customerEmail")}
                            </Typography>
                          </Box>
                        )}
                        {!!columns.customerPhone && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.properties.customerPhone")}
                            </Typography>
                          </Box>
                        )}
                        {!!columns.customerComment && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.properties.customerComment")}
                            </Typography>
                          </Box>
                        )}
                        {columns.sourceAddress && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.sourceAddress")}
                            </Typography>
                          </Box>
                        )}
                        {columns.currentAddress && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("tour.currentAddress")}
                            </Typography>
                          </Box>
                        )}
                        {columns.targetAddress && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("tour.targetAddress")}
                            </Typography>
                          </Box>
                        )}
                        {columns.targetFromDate && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("tour.targetFromDate")}
                            </Typography>
                          </Box>
                        )}
                        {columns.targetToDate && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("tour.targetToDate")}
                            </Typography>
                          </Box>
                        )}
                        {columns.sourceFromDate && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("tour.sourceFromDate")}
                            </Typography>
                          </Box>
                        )}
                        {columns.sourceToDate && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("tour.sourceToDate")}
                            </Typography>
                          </Box>
                        )}
                        {columns.icons && (
                          <Box display="flex" width="100%" padding={0.5}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                wordBreak: "break-word",
                              }}
                            >
                              {t("package.prop")}
                            </Typography>
                          </Box>
                        )}

                        <Box display="flex" width="50%" padding={0.5} />
                      </Box>
                    </>
                  )}
                  <Box maxHeight="60vh" overflow="auto">
                    {!!packagesQuery.data?.page.content.length ? (
                      packagesQuery.data?.page.content.map(value => (
                        <Box key={value.id} width="fit-content" minWidth="100%">
                          <PackageListItem
                            key={value.id}
                            pack={value}
                            date={date}
                            columns={columns}
                            setWarningData={setWarningData}
                            setAddressData={setAddressData}
                            enableMultiSelect={enableMultiSelect}
                            selectedPackageList={selectedPackages}
                            addPackage={addPackage}
                            removePackage={removePackage}
                          />
                        </Box>
                      ))
                    ) : (
                      <Box
                        fontWeight="bold"
                        fontSize={16}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={100}
                      >
                        {t("tour.noPackage")}
                      </Box>
                    )}
                    {packagesQuery.data && packagesQuery?.data?.page.totalPages > 1 && (
                      <Pagination
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "24px 0",
                        }}
                        count={packagesQuery?.data?.page.totalPages}
                        color="primary"
                        page={packagesQuery.data.page.number + 1}
                        onChange={(e, page) => {
                          window.scrollTo(0, 0);
                          setPage(page - 1);
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            <Divider
              orientation="vertical"
              style={{
                height: "100%",
                marginLeft: 12,
                marginRight: 12,
                color: "black",
              }}
            />
          </Box>
          <Box width="23%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={event => {
                event.stopPropagation();
                setIsntLight(isntLight => !isntLight);
              }}
            >
              <Typography variant="h6" style={{ fontWeight: "bold", wordBreak: "break-word" }}>
                {t("tour.vehicles")}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  paddingLeft: 6,
                  paddingRight: 12,
                  marginRight: 0,
                  border: `1px solid ${palette.main}`,
                  borderRadius: 50,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                <Switch checked={isntLight} onChange={(_, checked) => setIsntLight(checked)} />
                <Typography color="primary" style={{ fontWeight: "bold", fontSize: 14 }}>
                  {t("tour.moreData")}
                </Typography>
              </Box>
            </Box>
            {vehiclesQuery.isFetching ? (
              <Box display="flex" alignItems="center" justifyContent="center" height="300px">
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!!vehiclesQuery.data?.length && (
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      height={50}
                      padding="0 8px"
                    >
                      {enableMultiSelect && <Box width="9%"></Box>}
                      <Box
                        width={enableMultiSelect ? "30%" : "33%"}
                        fontWeight="bold"
                        fontSize={16}
                      >
                        {t("tour.licensePlateNumber")}
                      </Box>
                      <Box
                        width={enableMultiSelect ? "30%" : "33%"}
                        fontWeight="bold"
                        fontSize={16}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {t("tour.volume")}
                      </Box>
                      <Box
                        width={enableMultiSelect ? "30%" : "33%"}
                        fontWeight="bold"
                        fontSize={16}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {t("tour.weight")}
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
                <Box maxHeight="70vh" overflow="auto">
                  {!!vehiclesQuery.data?.length ? (
                    vehiclesQuery.data?.map((tour: TourPlan) => {
                      return (
                        <VehicleRow
                          key={tour.vehicleEntry.id}
                          refetch={vehiclesQuery.refetch}
                          tour={tour}
                          onClick={() => setOpen(true)}
                          setSelected={setSelected}
                          enableMultiSelect={enableMultiSelect}
                          selectedVehicle={selectedVehicle}
                          setSelectedVehicle={setSelectedVehicle}
                          isntLight={isntLight}
                        />
                      );
                    })
                  ) : (
                    <Box
                      fontWeight="bold"
                      fontSize={16}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height={100}
                    >
                      {t("tour.noVehicle")}
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
          <TourSortingModal
            open={open}
            date={date}
            refetch={() => {
              vehiclesQuery.refetch();
              packagesQuery.refetch();
            }}
            onClose={() => setOpen(false)}
            tourPlanProp={vehiclesQuery.data?.find((data: TourPlan) => {
              return data?.vehicleEntry.id === selected?.vehicleEntry.id;
            })}
          />
          <WarningModal
            warningData={warningData}
            setWarningData={setWarningData}
            onSubmit={(data: any) => setAddressData(data)}
          />
          <AddressModal
            addressData={addressData}
            setAddressData={setAddressData}
            setWarningData={setWarningData}
            vehicleRefetch={vehiclesQuery.refetch}
            packageRefetch={packagesQuery.refetch}
            toWarehouseChecked={toWarehouseChecked}
            setToWarehouseChecked={setToWarehouseChecked}
            enableMultiSelect={enableMultiSelect}
            resetMultiSelection={resetMultiSelection}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Tour;
