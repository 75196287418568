import { TextField } from "@material-ui/core";
import JoditEditor from "jodit-react";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX } from "shared/util/validation";

export type ContactFormValues = {
  name: string;
  email: string;
  phone: string;
  comment: string;
};

const ContactForm = () => {
  const { t } = useTranslation();
  const form = useFormContext<ContactFormValues>();

  const textAreaConfig = useMemo(() => {
    return {
      minHeight: 400,
      maxHeight: 700,
      showPlaceholder: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
    } as any;
  }, []);

  return (
    <>
      <TextField
        name="name"
        label={t("common:field.name")}
        inputRef={form.register({
          required: { value: true, message: t("validation:required") },
        })}
        error={form.errors.name && true}
        helperText={form.errors.name?.message}
      />
      <TextField
        name="email"
        type="email"
        label={t("common:field.email")}
        inputRef={form.register({
          required: { value: true, message: t("validation:required") },
          pattern: {
            value: EMAIL_REGEX,
            message: t("validation:invalidFormat", {
              subject: t("validation:caption.subject.email"),
            }),
          },
        })}
        error={form.errors.email && true}
        helperText={form.errors.email?.message}
      />
      <TextField
        name="phone"
        placeholder="+36"
        defaultValue="+36"
        label={t("common:field.phone")}
        InputLabelProps={{ required: true, shrink: true }}
        inputRef={form.register({
          required: {
            value: true,
            message: t("validation:required"),
          },
        })}
        error={form.errors?.phone && true}
        helperText={form.errors?.phone?.message}
      />
      <Controller
        name="comment"
        defaultValue=""
        render={props => (
          <JoditEditor
            config={textAreaConfig}
            ref={props.ref}
            value={props.value}
            onChange={content => {
              props.onChange(content);
            }}
          />
        )}
      />
    </>
  );
};

export default ContactForm;
