import { Box, Button, CircularProgress, MenuItem, TextField } from "@material-ui/core";
import { Add, GetApp } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import Layout from "components/layout/Layout";
import ColumnSelector from "components/package/ColumnSelector";
import PackageFilter from "components/package/PackageFilter";
import PackageList, { ColumnType } from "components/package/PackageList";
import { ROWS_PER_PAGE_OPTIONS } from "config/constants";
import { RootState } from "config/store";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import usePermissions from "shared/hooks/usePermissions";
import { exportPackages, searchPackages } from "shared/network/package.api";
import { Package } from "shared/types";
import fileDownload from "shared/util/fileDownload";
import PackageTimeWindowModal from "./PackageTimeWindowModal";

export type IsDescType = "ASC" | "DESC";
export type RadioGroupType = "YES" | "NO" | "UNDEFINED";

export type FilterValues = {
  search?: string;
  sort?: string;
  isDesc?: IsDescType;
  sourceDateFrom?: string;
  sourceDateTo?: string;
  createdOnFrom?: string;
  createdOnTo?: string;
  statuses?: string[];
  targetDateFrom?: string;
  targetDateTo?: string;
  fromSuccessfulDeliveryTime?: string;
  toSuccessfulDeliveryTime?: string;
  vehicleId?: number;
  warehouseId?: number;
  shopId?: number;
  trackingNumber?: string;
  sourceAddressZipCode?: string;
  sourceAddressCity?: string;
  sourceAddressAreaName?: string;
  sourceAddressAreaType?: string;
  sourceAddressHouseNumber?: string;
  currentAddressZipCode?: string;
  currentAddressCity?: string;
  currentAddressAreaName?: string;
  currentAddressAreaType?: string;
  currentAddressHouseNumber?: string;
  targetAddressZipCode?: string;
  targetAddressCity?: string;
  targetAddressAreaName?: string;
  targetAddressAreaType?: string;
  targetAddressHouseNumber?: string;
  shopPackagesNumber?: string;
  customerName?: string;
  companyId?: number;
  targetDateNull?: RadioGroupType;
  isAskLoader?: RadioGroupType;
  isDeliveryToWarehouse?: RadioGroupType;
  failedDelivery?: RadioGroupType;
  isHazardous?: RadioGroupType;
  isFragile?: RadioGroupType;
  isStackable?: RadioGroupType;
  isCashOnDelivery?: RadioGroupType;
  isExpressDelivery?: RadioGroupType;
  plannedToTour?: RadioGroupType;
  markedToReturn?: RadioGroupType;
  ignoreStatuses?: boolean;
};

const PackageListView = () => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState<number>(
    parseInt(window.sessionStorage.getItem("PackagePageSize") || JSON.stringify(10)),
  );
  const [page, setPage] = useState(
    parseInt(window.sessionStorage.getItem("PackagePageNumber") || JSON.stringify(0)),
  );
  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useState<FilterValues>({
    sort: "CREATED_ON",
    isDesc: "DESC",
  });
  const { isCustomerService, isAdmin, checkPermissions } = usePermissions();
  const currentCompany = useSelector((state: RootState) => state.application.currentCompany);

  useEffect(() => {
    const savedFilters = sessionStorage.getItem("package-list-filter");
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    }
  }, []);

  const packageQuery = useQuery(
    ["packages", page, filters, pageSize],
    async () => {
      const { data } = await searchPackages({
        pageNumber: page,
        pageSize: pageSize,
        data: {
          ...filters,
          sort: filters.sort,
          isDesc: filters.isDesc === "DESC",
          ignoreStatuses: false,
          companyId:
            checkPermissions({
              requestedPermissions: ["SHOPKEEPER"],
            }) &&
            !isAdmin &&
            !isCustomerService
              ? currentCompany?.id
              : undefined,
          targetDateNull:
            filters.targetDateNull === "YES"
              ? true
              : filters.targetDateNull === "NO"
              ? false
              : undefined,
          isAskLoader:
            filters.isAskLoader === "YES" ? true : filters.isAskLoader === "NO" ? false : undefined,
          isHazardous:
            filters.isHazardous === "YES" ? true : filters.isHazardous === "NO" ? false : undefined,
          isFragile:
            filters.isFragile === "YES" ? true : filters.isFragile === "NO" ? false : undefined,
          isStackable:
            filters.isStackable === "YES" ? true : filters.isStackable === "NO" ? false : undefined,
          isCashOnDelivery:
            filters.isCashOnDelivery === "YES"
              ? true
              : filters.isCashOnDelivery === "NO"
              ? false
              : undefined,
          isExpressDelivery:
            filters.isExpressDelivery === "YES"
              ? true
              : filters.isExpressDelivery === "NO"
              ? false
              : undefined,
          plannedToTour:
            filters.plannedToTour === "YES"
              ? true
              : filters.plannedToTour === "NO"
              ? false
              : undefined,
          markedToReturn:
            filters.markedToReturn === "YES"
              ? true
              : filters.markedToReturn === "NO"
              ? false
              : undefined,
          isDeliveryToWarehouse:
            filters.isDeliveryToWarehouse === "YES"
              ? true
              : filters.isDeliveryToWarehouse === "NO"
              ? false
              : undefined,
          failedDelivery:
            filters.failedDelivery === "YES"
              ? true
              : filters.failedDelivery === "NO"
              ? false
              : undefined,
        },
      });
      return data;
    },
    { enabled: !!currentCompany },
  );

  const downloadMutation = useMutation(
    async () => {
      const { data } = await exportPackages({
        ...filters,
        sort: filters.sort,
        isDesc: filters.isDesc === "DESC",
        ignoreStatuses: false,
        companyId:
          checkPermissions({
            requestedPermissions: ["SHOPKEEPER"],
          }) && !isAdmin
            ? currentCompany?.id
            : undefined,
        targetDateNull:
          filters.targetDateNull === "YES"
            ? true
            : filters.targetDateNull === "NO"
            ? false
            : undefined,
        isAskLoader:
          filters.isAskLoader === "YES" ? true : filters.isAskLoader === "NO" ? false : undefined,
        isHazardous:
          filters.isHazardous === "YES" ? true : filters.isHazardous === "NO" ? false : undefined,
        isFragile:
          filters.isFragile === "YES" ? true : filters.isFragile === "NO" ? false : undefined,
        isStackable:
          filters.isStackable === "YES" ? true : filters.isStackable === "NO" ? false : undefined,
        isCashOnDelivery:
          filters.isCashOnDelivery === "YES"
            ? true
            : filters.isCashOnDelivery === "NO"
            ? false
            : undefined,
        isExpressDelivery:
          filters.isExpressDelivery === "YES"
            ? true
            : filters.isExpressDelivery === "NO"
            ? false
            : undefined,
        plannedToTour:
          filters.plannedToTour === "YES"
            ? true
            : filters.plannedToTour === "NO"
            ? false
            : undefined,
        markedToReturn:
          filters.markedToReturn === "YES"
            ? true
            : filters.markedToReturn === "NO"
            ? false
            : undefined,
        isDeliveryToWarehouse:
          filters.isDeliveryToWarehouse === "YES"
            ? true
            : filters.isDeliveryToWarehouse === "NO"
            ? false
            : undefined,
        failedDelivery:
          filters.isDeliveryToWarehouse === "YES"
            ? true
            : filters.isDeliveryToWarehouse === "NO"
            ? false
            : undefined,
      });
      return data;
    },
    {
      onSuccess: data => {
        fileDownload(data, `Csomagok (${format(new Date(), "P", { locale: hu })}).xls`);
      },
    },
  );

  const [columnOpen, setColumnOpen] = useState(false);
  const [columns, setColumns] = useState<ColumnType>({
    status: true,
    packageName: false,
    trackingNumber: true,
    volume: false,
    storageCode: false,
    weight: false,
    icons: true,
    companyName: false,
    shopPackageNumber: false,
    customerComment: false,
    customerEmail: false,
    customerName: false,
    customerPhone: false,
    sourceAddress: false,
    currentAddress: false,
    targetAddress: true,
    targetFromDate: false,
    targetToDate: false,
    sourceFromDate: false,
    sourceToDate: false,
    returnGoods: false,
    cduNumber: false,
    orderShopNumber: false,
    orderExternalNumber: false,
  });

  useEffect(() => {
    const temp = localStorage.getItem("packagelist-package-columns");
    if (temp) {
      setColumns(JSON.parse(temp));
    }
    window.sessionStorage.setItem("PackagePageNumber", JSON.stringify(page));
  }, [page]);

  const [enableMultiSelect, setEnableMultiSelect] = useState(false);
  const [selectedPackages, setSelectedPackages] = useState<Package[]>([]);
  const [timeWindowOpen, setTimeWindowOpen] = useState(false);

  function resetMultiSelection() {
    setEnableMultiSelect(false);
    setSelectedPackages([]);
    packageQuery.refetch();
  }

  function addPackage(pack: Package) {
    setSelectedPackages(selectedPackages => [...selectedPackages, pack]);
  }

  function removePackage(pack: Package) {
    setSelectedPackages(selectedPackages.filter(entry => entry.id !== pack.id));
  }

  function startMultiPlanning() {
    setTimeWindowOpen(true);
  }

  return (
    <Layout
      title={t("package.list")}
      actionButton={
        <>
          {(checkPermissions({
            requestedPermissions: ["SHOPKEEPER"],
          }) ||
            isAdmin ||
            isCustomerService) && (
            <>
              <Button
                onClick={() => downloadMutation.mutate()}
                variant="outlined"
                color="primary"
                style={{ height: 40, marginRight: 8 }}
                startIcon={
                  downloadMutation.isLoading ? (
                    <CircularProgress style={{ height: 20, width: 20 }} />
                  ) : (
                    <GetApp />
                  )
                }
                disabled={downloadMutation.isLoading}
              >
                {t("common:button.export")}
              </Button>
              <Button
                size="medium"
                color="primary"
                variant="outlined"
                component={Link}
                to="/package/create"
                startIcon={<Add />}
                style={{ height: 40 }}
              >
                {t("package.create")}
              </Button>
            </>
          )}
        </>
      }
    >
      <ColumnSelector
        type="packagelist"
        open={columnOpen}
        columns={columns}
        setColumns={setColumns}
        setColumnOpen={setColumnOpen}
      />
      <PackageFilter
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        filters={filters}
        setFilters={setFilters}
        setPage={setPage}
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        columnOpen={columnOpen}
        setColumnOpen={setColumnOpen}
        enableMultiSelect={enableMultiSelect}
        multiSelectType={"time"}
        setEnableMultiSelect={setEnableMultiSelect}
        startMultiPlanning={startMultiPlanning}
        packagesSelected={!isEmpty(selectedPackages)}
        resetMultiSelection={resetMultiSelection}
        storageKey="package-list-filter"
      />
      <PackageList
        columns={columns}
        packages={packageQuery.data?.page.content}
        refetch={packageQuery.refetch}
        loading={packageQuery.isFetching}
        enableMultiSelect={enableMultiSelect}
        selectedPackageList={selectedPackages}
        addPackage={addPackage}
        removePackage={removePackage}
      />
      {packageQuery.data && (
        <Box display="flex" justifyContent="center" alignItems="center" gridGap={8}>
          <Box>
            <TextField
              margin="none"
              select
              defaultValue={pageSize}
              size="small"
              onChange={event => {
                setPageSize(Number.parseInt(event.target.value));
                setPage(0);
                sessionStorage.setItem("PackagePageSize", event.target.value);
                sessionStorage.setItem("PackagePageNumber", "0");
              }}
            >
              {ROWS_PER_PAGE_OPTIONS.map((option, index) => {
                return (
                  <MenuItem value={option} key={index}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Pagination
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "24px 0",
            }}
            count={packageQuery?.data?.page.totalPages}
            color="primary"
            page={packageQuery.data.page.number + 1}
            onChange={(e, page) => {
              sessionStorage.setItem("PackagePageNumber", JSON.stringify(page - 1));
              setPage(page - 1);
            }}
          />
        </Box>
      )}
      <PackageTimeWindowModal
        open={timeWindowOpen}
        setOpen={setTimeWindowOpen}
        packageList={selectedPackages}
        refetch={resetMultiSelection}
      />
    </Layout>
  );
};

export default PackageListView;
