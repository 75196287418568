import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  DeleteOutlined,
  ViewColumnOutlined,
  Close,
  FilterList,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { AREA_TYPES, SORT_BY, statuses } from "config/constants";
import { palette } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import usePermissions from "shared/hooks/usePermissions";
import { getAllShops } from "shared/network/shop.api";
import { getAllVehicles } from "shared/network/vehicle.api";
import { getWarehouses } from "shared/network/warehouse.api";
import { FilterValues, IsDescType } from "views/package/PackageListView";

type Props = {
  disableWarehouseFilter?: boolean;
  open: boolean;
  onClose: () => void;
  filters: FilterValues;
  setFilters: Dispatch<SetStateAction<FilterValues>>;
  setPage: Dispatch<SetStateAction<number>>;
  filterOpen: boolean;
  setFilterOpen: Dispatch<SetStateAction<boolean>>;
  columnOpen: boolean;
  setColumnOpen: Dispatch<SetStateAction<boolean>>;
  enableMultiSelect?: boolean;
  multiSelectType?: string; // "time" -> timeWindow on package list
  setEnableMultiSelect?: Dispatch<SetStateAction<boolean>>;
  startMultiPlanning?: () => void;
  packagesSelected?: boolean;
  vehicleSelected?: boolean;
  resetMultiSelection?: () => void;
  storageKey: string;
};

const PackageFilter = ({
  disableWarehouseFilter,
  open,
  onClose,
  filters,
  setFilters,
  setPage,
  filterOpen,
  setFilterOpen,
  columnOpen,
  setColumnOpen,
  enableMultiSelect,
  multiSelectType,
  setEnableMultiSelect,
  startMultiPlanning,
  packagesSelected,
  vehicleSelected,
  resetMultiSelection,
  storageKey,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const filterForm = useForm<FilterValues>();

  const { isCustomerService, isAdmin, checkPermissions } = usePermissions();

  const isWarehouseKeeper = checkPermissions({
    requestedPermissions: ["WAREHOUSEKEEPER", "WAREHOUSEKEEPER_ADMIN"],
  });

  const shopQuery = useQuery("shopQuery", async () => {
    const { data } = await getAllShops();
    return data.items;
  });

  const vehicleQuery = useQuery("vehicleQueryPackageFilter", async () => {
    const { data } = await getAllVehicles();
    return data.items;
  });

  const warehouseQuery = useQuery("warehouseQueryPackageFilter", async () => {
    const { data } = await getWarehouses();
    return data.items;
  });

  useEffect(() => {
    filterForm.reset(filters);
    resetMultiSelection && resetMultiSelection();
  }, [filters]); //eslint-disable-line

  function onFilter(values: FilterValues) {
    setPage(0);
    const temp = {
      search: values.search || undefined,
      sort: values.sort,
      isDesc: (values.isDesc === "DESC" ? "DESC" : "ASC") as IsDescType | undefined,
      sourceDateFrom: values.sourceDateFrom || undefined,
      sourceDateTo: values.sourceDateTo || undefined,
      statuses: values.statuses || undefined,
      targetDateFrom: values.targetDateFrom || undefined,
      targetDateTo: values.targetDateTo || undefined,
      fromSuccessfulDeliveryTime: values.fromSuccessfulDeliveryTime || undefined,
      toSuccessfulDeliveryTime: values.toSuccessfulDeliveryTime || undefined,
      createdOnFrom: values.createdOnFrom || undefined,
      createdOnTo: values.createdOnTo || undefined,
      vehicleId: values.vehicleId || undefined,
      warehouseId: values.warehouseId || undefined,
      shopId: values.shopId || undefined,
      trackingNumber: values?.trackingNumber?.trim() || undefined,
      currentAddressZipCode: values.currentAddressZipCode || undefined,
      currentAddressCity: values.currentAddressCity || undefined,
      currentAddressAreaName: values.currentAddressAreaName || undefined,
      currentAddressAreaType: values.currentAddressAreaType || undefined,
      currentAddressHouseNumber: values.currentAddressHouseNumber || undefined,
      targetAddressZipCode: values.targetAddressZipCode || undefined,
      targetAddressCity: values.targetAddressCity || undefined,
      targetAddressAreaName: values.targetAddressAreaName || undefined,
      targetAddressAreaType: values.targetAddressAreaType || undefined,
      targetAddressHouseNumber: values.targetAddressHouseNumber || undefined,
      sourceAddressZipCode: values.sourceAddressZipCode || undefined,
      sourceAddressCity: values.sourceAddressCity || undefined,
      sourceAddressAreaName: values.sourceAddressAreaName || undefined,
      sourceAddressAreaType: values.sourceAddressAreaType || undefined,
      sourceAddressHouseNumber: values.sourceAddressHouseNumber || undefined,
      customerName: values.customerName || undefined,
      shopPackagesNumber: values.shopPackagesNumber || undefined,
      targetDateNull: values.targetDateNull || undefined,
      isAskLoader: values.isAskLoader || undefined,
      isDeliveryToWarehouse: values.isDeliveryToWarehouse || undefined,
      failedDelivery: values.failedDelivery || undefined,
      isHazardous: values.isHazardous || undefined,
      isFragile: values.isFragile || undefined,
      isStackable: values.isStackable || undefined,
      isCashOnDelivery: values.isCashOnDelivery || undefined,
      isExpressDelivery: values.isExpressDelivery || undefined,
      plannedToTour: values.plannedToTour || undefined,
      markedToReturn: values.markedToReturn || undefined,
    };
    sessionStorage.setItem(storageKey, JSON.stringify(temp));
    setFilters(temp);
    onClose();
  }

  return (
    <>
      <Box display="flex" flexWrap="wrap" gridGap={8} mb={1}>
        {!!setEnableMultiSelect && (
          <Chip
            variant={enableMultiSelect ? "default" : "outlined"}
            icon={<ViewColumnOutlined />}
            label={
              <Box display="flex">
                <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
                  {multiSelectType === "time"
                    ? !enableMultiSelect
                      ? t("package.timeWindow.startSelect")
                      : t("package.timeWindow.endSelect")
                    : !enableMultiSelect
                    ? t("common:toggleGroupPlanning")
                    : t("common:finishGroupPlanning")}
                </Typography>
              </Box>
            }
            color="primary"
            onClick={() => {
              if (enableMultiSelect && multiSelectType === "time") {
                if (!packagesSelected) {
                  setEnableMultiSelect(false);
                  resetMultiSelection?.();
                } else {
                  startMultiPlanning?.();
                }
              } else if (enableMultiSelect && startMultiPlanning) {
                if (!packagesSelected && !vehicleSelected) {
                  setEnableMultiSelect(false);
                  resetMultiSelection && resetMultiSelection();
                } else if (vehicleSelected && !packagesSelected) {
                  setEnableMultiSelect(false);
                  resetMultiSelection && resetMultiSelection();
                } else if (packagesSelected && !vehicleSelected) {
                  enqueueSnackbar(t("common:notification.multiSelectPlanning.needVehicle"), {
                    variant: "error",
                  });
                } else {
                  startMultiPlanning();
                }
              } else if (!enableMultiSelect) {
                setEnableMultiSelect(true);
              }
            }}
            clickable
          />
        )}
        <Chip
          variant={columnOpen ? "default" : "outlined"}
          icon={<ViewColumnOutlined />}
          label={
            <Box display="flex">
              <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
                {t("common:columns")}
              </Typography>
            </Box>
          }
          color="primary"
          onClick={() => setColumnOpen(true)}
          clickable
        />
        <Chip
          variant={filterOpen ? "default" : "outlined"}
          icon={<FilterList />}
          label={
            <Box display="flex">
              <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
                {t("common:filter")}
              </Typography>
            </Box>
          }
          color="primary"
          onClick={() => setFilterOpen(!filterOpen)}
          clickable
        />
        <Chip
          variant={"outlined"}
          icon={<DeleteOutlined />}
          label={
            <Box display="flex">
              <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
                {t("packageFilters.deleteAll")}
              </Typography>
            </Box>
          }
          color="primary"
          onClick={() => {
            setFilters(prevState => {
              sessionStorage.setItem(
                storageKey,
                JSON.stringify({
                  sort: prevState.sort,
                  isDesc: prevState.isDesc,
                }),
              );
              return { sort: prevState.sort, isDesc: prevState.isDesc };
            });
          }}
          clickable
        />
        {Object.entries(filters)
          .filter(([key, value]) => {
            if (value !== undefined) {
              if (typeof value === "number") {
                return true;
              } else if (key === "ignoreStatuses") {
                return false;
              } else if (
                key === "sourceDateFrom" ||
                key === "sourceDateTo" ||
                key === "targetDateFrom" ||
                key === "targetDateTo" ||
                key === "createdOnFrom" ||
                key === "createdOnTo" ||
                key === "fromSuccessfulDeliveryTime" ||
                key === "toSuccessfulDeliveryTime"
              ) {
                return true;
              } else if (
                (value === "YES" || value === "NO") &&
                (key === "targetDateNull" ||
                  key === "isAskLoader" ||
                  key === "isHazardous" ||
                  key === "isFragile" ||
                  key === "isStackable" ||
                  key === "isCashOnDelivery" ||
                  key === "isExpressDelivery" ||
                  key === "plannedToTour" ||
                  key === "markedToReturn" ||
                  key === "isDeliveryToWarehouse" ||
                  key === "failedDelivery")
              ) {
                return true;
              } else if (
                value === "UNDEFINED" &&
                (key === "targetDateNull" ||
                  key === "isAskLoader" ||
                  key === "isHazardous" ||
                  key === "isFragile" ||
                  key === "isStackable" ||
                  key === "isCashOnDelivery" ||
                  key === "isExpressDelivery" ||
                  key === "plannedToTour" ||
                  key === "markedToReturn" ||
                  key === "isDeliveryToWarehouse" ||
                  key === "failedDelivery")
              ) {
                return false;
              } else if (typeof value !== "boolean" && value.length) {
                return true;
              }
            }
            return false;
          })
          .map(([key, value]) => {
            let temp;
            if (value) {
              if (
                key === "sourceDateFrom" ||
                key === "sourceDateTo" ||
                key === "targetDateFrom" ||
                key === "targetDateTo" ||
                key === "createdOnFrom" ||
                key === "createdOnTo" ||
                key === "fromSuccessfulDeliveryTime" ||
                key === "toSuccessfulDeliveryTime"
              ) {
                temp = format(new Date(value as string), "yyyy.MM.dd. HH:mm");
              } else if (key === "sort") {
                temp = t(`package.sort.${value}`);
              } else if (key === "isDesc") {
                temp = value === "DESC" ? t("package.desc") : t("package.asc");
              } else if (key === "isDesc") {
                temp = value;
              } else if (
                key === "statuses" &&
                !(typeof value === "number" || typeof value === "boolean")
              ) {
                temp = `${value.length} db`;
              } else if (key === "shopId") {
                temp = shopQuery.data?.find(shop => shop.id === value)?.prefix;
              } else if (key === "vehicleId") {
                temp = vehicleQuery.data?.find(shop => shop.id === value)?.licensePlateNumber;
              } else if (key === "warehouseId") {
                temp = warehouseQuery.data?.find(warehouse => warehouse.id === value)?.name;
              } else if (
                key === "targetDateNull" ||
                key === "isAskLoader" ||
                key === "isHazardous" ||
                key === "isStackable" ||
                key === "isFragile" ||
                key === "isCashOnDelivery" ||
                key === "isExpressDelivery" ||
                key === "plannedToTour" ||
                key === "markedToReturn" ||
                key === "isDeliveryToWarehouse" ||
                key === "failedDelivery"
              ) {
                if (value === "YES") {
                  temp = "Igen";
                }
                if (value === "NO") {
                  temp = "Nem";
                }
                if (value === "UNDEFINED") {
                  temp = "Nincs megadva";
                }
              } else if (
                key === "sourceAddressAreaType" ||
                key === "currentAddressAreaType" ||
                key === "targetAddressAreaType"
              ) {
                temp = t(`common:areaType.${value}`);
              } else {
                temp = value;
              }
            }
            return (
              <Chip
                key={key}
                variant={key === "sort" || key === "isDesc" ? "outlined" : "default"}
                label={
                  <Box display="flex">
                    <Typography style={{ fontWeight: "bold", fontSize: 12 }}>
                      {t(`packageFilters.${key}`)}
                      :&nbsp;
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>{temp}</Typography>
                  </Box>
                }
                onDelete={
                  key === "sort" || key === "isDesc"
                    ? undefined
                    : () =>
                        setFilters(prevState => {
                          sessionStorage.setItem(
                            storageKey,
                            JSON.stringify({ ...prevState, [key]: undefined }),
                          );
                          return { ...prevState, [key]: undefined };
                        })
                }
                color="secondary"
              />
            );
          })}
      </Box>
      <FormProvider {...filterForm}>
        <Drawer
          open={open}
          anchor="right"
          onClose={onClose}
          PaperProps={{
            component: "form",
            onSubmit: filterForm.handleSubmit(onFilter),
          }}
        >
          <DialogTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                {t("package.filter")}
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ maxWidth: 500 }}>
            <Typography style={{ color: palette.main }}>{t("package.order")}</Typography>
            <Divider style={{ margin: "8px 0" }} />
            <Grid container spacing={2} style={{ padding: "12px 0" }}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="sort"
                  defaultValue="CREATED_ON"
                  render={props => (
                    <TextField
                      {...props}
                      label={t("package.sortBy")}
                      select
                      size="small"
                      margin="none"
                    >
                      {SORT_BY.map(sort => (
                        <MenuItem key={sort} value={sort}>
                          {t(`package.sort.${sort}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="isDesc"
                  defaultValue="DESC"
                  render={props => (
                    <TextField
                      {...props}
                      label={t("package.orderBy")}
                      select
                      size="small"
                      margin="none"
                    >
                      <MenuItem value="ASC">
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          gridGap={8}
                          height={19}
                        >
                          <KeyboardArrowUp fontSize="small" />
                          <Typography>{t("package.asc")}</Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value="DESC">
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          gridGap={8}
                          height={19}
                        >
                          <KeyboardArrowDown fontSize="small" />
                          <Typography>{t("package.desc")}</Typography>
                        </Box>
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
            <Typography style={{ color: palette.main }}>Szűrők</Typography>
            <Divider style={{ margin: "8px 0" }} />
            <Grid container spacing={2} style={{ padding: "12px 0" }}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="shopId"
                  defaultValue=""
                  render={props => (
                    <TextField
                      {...props}
                      margin="none"
                      label={t("package.shop")}
                      select
                      disabled={!(isCustomerService || isAdmin || isWarehouseKeeper)}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>{t("common:choose")}</em>
                      </MenuItem>
                      {shopQuery?.data?.map(shop => (
                        <MenuItem key={shop.id} value={shop.id}>
                          {shop.prefix}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="vehicleId"
                  defaultValue=""
                  render={props => (
                    <TextField
                      {...props}
                      margin="none"
                      label={t("package.vehicle")}
                      select
                      size="small"
                    >
                      <MenuItem value="">
                        <em>{t("common:choose")}</em>
                      </MenuItem>
                      {vehicleQuery?.data?.map(vehice => (
                        <MenuItem key={vehice.id} value={vehice.id}>
                          {vehice.licensePlateNumber}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="warehouseId"
                  defaultValue=""
                  render={props => (
                    <TextField
                      {...props}
                      margin="none"
                      label={t("package.warehouse")}
                      disabled={disableWarehouseFilter}
                      select
                      size="small"
                    >
                      <MenuItem value="">
                        <em>{t("common:choose")}</em>
                      </MenuItem>
                      {warehouseQuery?.data?.map(warehouse => (
                        <MenuItem key={warehouse.id} value={warehouse.id}>
                          {warehouse.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="statuses"
                  defaultValue={[]}
                  render={props => (
                    <TextField
                      {...props}
                      margin="none"
                      label={t("package.status.title")}
                      select
                      size="small"
                      SelectProps={{ multiple: true }}
                    >
                      <MenuItem value={[]} disabled>
                        <em>{t("common:choose")}</em>
                      </MenuItem>
                      {statuses.map(status => (
                        <MenuItem key={status} value={status}>
                          {t(`package.status.${status}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="sourceDateFrom"
                  defaultValue={null}
                  render={({ ref, ...props }) => (
                    <KeyboardDateTimePicker
                      {...props}
                      margin="none"
                      size="small"
                      format="yyyy.MM.dd. HH:mm"
                      label={t("packageFilters.sourceDateFrom")}
                      autoOk
                      ampm={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="sourceDateTo"
                  defaultValue={null}
                  render={({ ref, ...props }) => (
                    <KeyboardDateTimePicker
                      {...props}
                      margin="none"
                      size="small"
                      format="yyyy.MM.dd. HH:mm"
                      label={t("packageFilters.sourceDateTo")}
                      autoOk
                      ampm={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="targetDateFrom"
                  defaultValue={null}
                  render={({ ref, ...props }) => (
                    <KeyboardDateTimePicker
                      {...props}
                      margin="none"
                      size="small"
                      format="yyyy.MM.dd. HH:mm"
                      label={t("packageFilters.targetDateFrom")}
                      autoOk
                      ampm={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="targetDateTo"
                  defaultValue={null}
                  render={({ ref, ...props }) => (
                    <KeyboardDateTimePicker
                      {...props}
                      margin="none"
                      size="small"
                      format="yyyy.MM.dd. HH:mm"
                      label={t("packageFilters.targetDateTo")}
                      autoOk
                      ampm={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="fromSuccessfulDeliveryTime"
                  defaultValue={null}
                  render={({ ref, ...props }) => (
                    <KeyboardDateTimePicker
                      {...props}
                      margin="none"
                      size="small"
                      format="yyyy.MM.dd. HH:mm"
                      label={t("packageFilters.fromSuccessfulDeliveryTime")}
                      autoOk
                      ampm={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="toSuccessfulDeliveryTime"
                  defaultValue={null}
                  render={({ ref, ...props }) => (
                    <KeyboardDateTimePicker
                      {...props}
                      margin="none"
                      size="small"
                      format="yyyy.MM.dd. HH:mm"
                      label={t("packageFilters.toSuccessfulDeliveryTime")}
                      autoOk
                      ampm={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="createdOnFrom"
                  defaultValue={null}
                  render={({ ref, ...props }) => (
                    <KeyboardDateTimePicker
                      {...props}
                      margin="none"
                      size="small"
                      format="yyyy.MM.dd. HH:mm"
                      label={t("packageFilters.createdOnFrom")}
                      autoOk
                      ampm={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="createdOnTo"
                  defaultValue={null}
                  render={({ ref, ...props }) => (
                    <KeyboardDateTimePicker
                      {...props}
                      margin="none"
                      size="small"
                      format="yyyy.MM.dd. HH:mm"
                      label={t("packageFilters.createdOnTo")}
                      autoOk
                      ampm={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="none"
                  name="trackingNumber"
                  label={t("package.trackingNumber")}
                  size="small"
                  inputRef={filterForm.register()}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="none"
                  name="shopPackagesNumber"
                  label="Megrendelésszám"
                  size="small"
                  inputRef={filterForm.register()}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="targetDateNull"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t("packageFilters.targetDateNull")}</FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("tour.no")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="isAskLoader"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t("packageFilters.isAskLoader")}</FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="isHazardous"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t("packageFilters.isHazardous")}</FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="isFragile"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t("packageFilters.isFragile")}</FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="isStackable"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t("packageFilters.isStackable")}</FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="isCashOnDelivery"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {t("packageFilters.isCashOnDelivery")}
                      </FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="isExpressDelivery"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {t("packageFilters.isExpressDelivery")}
                      </FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="plannedToTour"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t("packageFilters.plannedToTour")}</FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="markedToReturn"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{t("packageFilters.markedToReturn")}</FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="isDeliveryToWarehouse"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {t("package.properties.isDeliveryToWarehouse")}
                      </FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "0 8px" }}>
                <Controller
                  name="failedDelivery"
                  defaultValue="UNDEFINED"
                  render={props => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {t("package.properties.failedDelivery")}
                      </FormLabel>
                      <RadioGroup {...props} row onChange={e => props.onChange(e.target.value)}>
                        <FormControlLabel value="YES" control={<Radio />} label={t("tour.yes")} />
                        <FormControlLabel value="NO" control={<Radio />} label={t("tour.no")} />
                        <FormControlLabel
                          value="UNDEFINED"
                          control={<Radio />}
                          label={t("auditLog.noFilter")}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ color: palette.main }}>
                  {t("packageFilters.customerData")}
                </Typography>
                <Divider style={{ margin: "8px 0" }} />
                <Grid container spacing={2} style={{ padding: "12px 0" }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="none"
                      name="customerName"
                      label={t("userList.name")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="none"
                      name="customerEmail"
                      label={t("userList.email")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="none"
                      name="customerPhone"
                      label={t("userList.phone")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ color: palette.main }}>
                  {t("packageFilters.currentAddress")}
                </Typography>
                <Divider style={{ margin: "8px 0" }} />
                <Grid container spacing={2} style={{ padding: "12px 0" }}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      margin="none"
                      name="currentAddressZipCode"
                      label={t("common:field.address.zipCode")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      margin="none"
                      name="currentAddressCity"
                      label={t("common:field.address.city")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      margin="none"
                      name="currentAddressAreaName"
                      label={t("common:field.address.areaName")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="currentAddressAreaType"
                      defaultValue=""
                      render={props => (
                        <TextField
                          {...props}
                          margin="none"
                          select
                          label={t("common:field.address.areaType")}
                          InputLabelProps={{ shrink: true }}
                        >
                          {AREA_TYPES.map(type => (
                            <MenuItem key={type} value={type}>
                              {t(`common:areaType.${type}`)}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      margin="none"
                      name="currentAddressHouseNumber"
                      label={t("common:field.address.houseNumber")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ color: palette.main }}>
                  {t("packageFilters.sourceAddress")}
                </Typography>
                <Divider style={{ margin: "8px 0" }} />
                <Grid container spacing={2} style={{ padding: "12px 0" }}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      margin="none"
                      name="sourceAddressZipCode"
                      label={t("common:field.address.zipCode")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      margin="none"
                      name="sourceAddressCity"
                      label={t("common:field.address.city")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      margin="none"
                      name="sourceAddressAreaName"
                      label={t("common:field.address.areaName")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="sourceAddressAreaType"
                      defaultValue=""
                      render={props => (
                        <TextField
                          {...props}
                          margin="none"
                          select
                          label={t("common:field.address.areaType")}
                          InputLabelProps={{ shrink: true }}
                        >
                          {AREA_TYPES.map(type => (
                            <MenuItem key={type} value={type}>
                              {t(`common:areaType.${type}`)}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      margin="none"
                      name="sourceAddressHouseNumber"
                      label={t("common:field.address.houseNumber")}
                      size="small"
                      inputRef={filterForm.register()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ color: palette.main }}>
                {t("packageFilters.targetAddress")}
              </Typography>
              <Divider style={{ margin: "8px 0" }} />
              <Grid container spacing={2} style={{ padding: "12px 0" }}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    margin="none"
                    name="targetAddressZipCode"
                    label={t("common:field.address.zipCode")}
                    size="small"
                    inputRef={filterForm.register()}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    margin="none"
                    name="targetAddressCity"
                    label={t("common:field.address.city")}
                    size="small"
                    inputRef={filterForm.register()}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    margin="none"
                    name="targetAddressAreaName"
                    label={t("common:field.address.areaName")}
                    size="small"
                    inputRef={filterForm.register()}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="targetAddressAreaType"
                    defaultValue=""
                    render={props => (
                      <TextField
                        {...props}
                        margin="none"
                        select
                        label={t("common:field.address.areaType")}
                        InputLabelProps={{ shrink: true }}
                      >
                        {AREA_TYPES.map(type => (
                          <MenuItem key={type} value={type}>
                            {t(`common:areaType.${type}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    margin="none"
                    name="targetAddressHouseNumber"
                    label={t("common:field.address.houseNumber")}
                    size="small"
                    inputRef={filterForm.register()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs>
                <Box display="flex" justifyContent="center" m={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<FilterList />}
                  >
                    {t("package.filter")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogActions>
        </Drawer>
      </FormProvider>
    </>
  );
};

export default PackageFilter;
