import { Box, Checkbox, FormControlLabel, Grid, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Loading from "components/Loading";
import Button from "components/form/Button";
import { ARTICLE_TYPES } from "config/constants";
import JoditEditor from "jodit-react";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Article } from "shared/types";
import useStyles from "./styles";

type FormValues = {
  title: string;
  content: string;
  isActive: boolean;
  subjectType: string;
};

export type ArticleFormValues = FormValues;

type Props = {
  defaultValues?: Article;
  onSubmit: (values: FormValues) => void;
  isLoading?: boolean;
};

const ArticleForm = ({ onSubmit, defaultValues, isLoading = false }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { handleSubmit, register, errors, control } = useForm<FormValues>({
    defaultValues,
  });

  const textAreaConfig = useMemo(() => {
    return {
      minHeight: 600,
      maxHeight: 600,
      showPlaceholder: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
      uploader: {
        insertImageAsBase64URI: true,
      },
    } as any;
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="none"
            name="title"
            label={t("article.properties.title")}
            inputRef={register({
              required: { value: true, message: t("validation:required") },
              maxLength: {
                value: 128,
                message: t("validation:maxLength", { length: 128 }),
              },
            })}
            error={errors.title && true}
            helperText={errors.title?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="subjectType"
            defaultValue=""
            render={props => (
              <TextField
                {...props}
                margin="none"
                name="subjectType"
                label={t("article.properties.subjectType")}
                style={{ marginBottom: 24 }}
                error={errors.subjectType && true}
                helperText={errors.subjectType?.message}
                select
              >
                {ARTICLE_TYPES.map((type, key) => (
                  <MenuItem key={key} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            )}
            rules={{
              required: { value: true, message: t("validation:required") },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Loading />
          ) : (
            <div className={classes.editor}>
              <Controller
                control={control}
                name="content"
                defaultValue={defaultValues?.content ?? t("common:field.editorInitialText")}
                render={props => (
                  <JoditEditor
                    config={textAreaConfig}
                    ref={props.ref}
                    value={props.value}
                    onChange={content => {
                      props.onChange(content);
                    }}
                  />
                )}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <Controller
            name="isActive"
            id="isActive"
            control={control}
            defaultValue={false}
            render={props => (
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(_, checked) => {
                      props.onChange(checked);
                    }}
                  />
                }
                label={t("article.isActive")}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box justifyContent="center" display="flex" p={2} alignItems="center">
        <Button loading={isLoading} type="submit">
          {t("common:button.save")}
        </Button>
      </Box>
    </form>
  );
};

export default ArticleForm;
