import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Link, Typography, makeStyles } from "@material-ui/core";
import { Equalizer, LocalAtm } from "@material-ui/icons";
import BusinessIcon from "@material-ui/icons/Business";
import ExploreRoundedIcon from "@material-ui/icons/ExploreRounded";
import HomeWorkRoundedIcon from "@material-ui/icons/HomeWorkRounded";
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShippingRounded";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import Layout from "components/layout/Layout";
import { Link as RouterLink } from "components/router";
import { palette } from "config/theme";
import { useTranslation } from "react-i18next";
import usePermissions from "shared/hooks/usePermissions";
import VehicleList from "views/vehicle/VehicleList";
import WarehouseList from "views/warehouse/WarehouseList";

const useStyles = makeStyles(
  () => ({
    button: {
      fontSize: 20,
      padding: "12px 20px",
      "& svg": {
        height: 32,
        width: 32,
      },
    },
  }),
  { name: "Dashboard" },
);

const Dashboard = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isCustomerService, isAdmin, checkPermissions } = usePermissions();
  if (
    checkPermissions({
      requestedPermissions: ["SHIPPER"],
    }) &&
    !isCustomerService &&
    !isAdmin
  ) {
    return <VehicleList />;
  }
  if (
    checkPermissions({
      requestedPermissions: ["WAREHOUSEKEEPER", "WAREHOUSEKEEPER_ADMIN"],
    }) &&
    !isCustomerService &&
    !isAdmin
  ) {
    return <WarehouseList />;
  }

  return (
    <Layout title={t("common:mainPage")}>
      <Typography variant="h5">Üzlet</Typography>
      <Divider style={{ margin: "8px 0" }} />
      <Box display="flex" flexWrap="wrap" gridGap={8} marginY={2}>
        {checkPermissions({
          requestedPermissions: ["SHIPORGANIZER"],
        }) && (
          <>
            <Link component={RouterLink} to="/tour" underline="none">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ExploreRoundedIcon />}
                className={classes.button}
              >
                {t("tour.title")}
              </Button>
            </Link>
          </>
        )}
        {isAdmin && (
          <>
            <Link component={RouterLink} to="/report" underline="none">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Equalizer />}
                className={classes.button}
              >
                {t("report.title")}
              </Button>
            </Link>
            <Link component={RouterLink} to="/shipping-price" underline="none">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<LocalAtm />}
                className={classes.button}
              >
                {t("shippingPrice.title")}
              </Button>
            </Link>
          </>
        )}
        {(isCustomerService || isAdmin) && (
          <>
            <Link component={RouterLink} to="/tracking" underline="none">
              <Button
                variant="outlined"
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon={faMapMarkedAlt}
                    style={{
                      color: palette.main,
                    }}
                  />
                }
                className={classes.button}
              >
                {t("dashboard.follow")}
              </Button>
            </Link>
          </>
        )}
        {isAdmin && (
          <>
            <Link component={RouterLink} to="/deliveryextracharge" underline="none">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<MonetizationOnIcon />}
                className={classes.button}
              >
                {t("dashboard.extraFee")}
              </Button>
            </Link>
          </>
        )}
      </Box>
      <Typography variant="h5" style={{ margin: "8px 0" }}>
        {t("dashboard.data")}
      </Typography>
      <Divider style={{ margin: "8px 0" }} />
      <Box display="flex" flexWrap="wrap" gridGap={8} marginY={2}>
        {checkPermissions({
          requestedPermissions: ["SHOPKEEPER", "SHIPORGANIZER"],
        }) && (
          <Link component={RouterLink} to="/package" underline="none">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ViewModuleIcon />}
              className={classes.button}
            >
              {t("package.list")}
            </Button>
          </Link>
        )}
        {isAdmin && (
          <>
            <Link component={RouterLink} to="/user-list" underline="none">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<PeopleAltRoundedIcon />}
                className={classes.button}
              >
                {t("userList.title")}
              </Button>
            </Link>
            <Link component={RouterLink} to="/company" underline="none">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<BusinessIcon />}
                className={classes.button}
              >
                {t("company.list.title")}
              </Button>
            </Link>
          </>
        )}
        {(isAdmin ||
          isCustomerService ||
          checkPermissions({
            requestedPermissions: ["WAREHOUSEKEEPER", "WAREHOUSEKEEPER_ADMIN"],
          })) && (
          <Link component={RouterLink} to="/warehouse" underline="none">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<HomeWorkRoundedIcon />}
              className={classes.button}
            >
              {t("warehouse.list.title")}
            </Button>
          </Link>
        )}
        {checkPermissions({
          requestedPermissions: ["SHIPORGANIZER", "SHIPPER"],
        }) && (
          <Link component={RouterLink} to="/vehicle" underline="none">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<LocalShippingRoundedIcon />}
              className={classes.button}
            >
              {t("vehicle.list.title")}
            </Button>
          </Link>
        )}
      </Box>
    </Layout>
  );
};

export default Dashboard;
